/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CallContextRequest,
  CallRequest,
  GetCallsParams,
  HTTPValidationError,
  TranscriptionCall,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get call by id.
 * @summary Get Call
 */
export const getCall = (
  tenantUuid: string,
  callId: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TranscriptionCall>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/calls/${callId}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCallQueryKey = (tenantUuid: string, callId: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/calls/${callId}`] as const;
};

export const getGetCallQueryOptions = <
  TData = Awaited<ReturnType<typeof getCall>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  callId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCall>>, TError, TData>;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCallQueryKey(tenantUuid, callId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCall>>> = ({
    signal,
  }) => getCall(tenantUuid, callId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && callId),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getCall>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetCallQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCall>>
>;
export type GetCallQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Call
 */

export function useGetCall<
  TData = Awaited<ReturnType<typeof getCall>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  callId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCall>>, TError, TData>;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCallQueryOptions(tenantUuid, callId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get all calls.
 * @summary Get Calls
 */
export const getCalls = (
  tenantUuid: string,
  params?: GetCallsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TranscriptionCall[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/calls`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCallsQueryKey = (
  tenantUuid: string,
  params?: GetCallsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/calls`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCallsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCalls>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCallsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCalls>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCallsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCalls>>> = ({
    signal,
  }) => getCalls(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getCalls>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetCallsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCalls>>
>;
export type GetCallsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Calls
 */

export function useGetCalls<
  TData = Awaited<ReturnType<typeof getCalls>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCallsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCalls>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCallsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Make a call to the specified phone number using Twilio.
 * @summary Initiate a Call
 */
export const initiateCall = (
  tenantUuid: string,
  callRequest: CallRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/calls`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: callRequest,
      signal,
    },
    options
  );
};

export const getInitiateCallMutationOptions = <
  TData = Awaited<ReturnType<typeof initiateCall>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CallRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["initiateCall"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof initiateCall>>,
    { tenantUuid: string; data: CallRequest }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return initiateCall(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CallRequest },
    TContext
  >;
};

export type InitiateCallMutationResult = NonNullable<
  Awaited<ReturnType<typeof initiateCall>>
>;
export type InitiateCallMutationBody = CallRequest;
export type InitiateCallMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Initiate a Call
 */
export const useInitiateCall = <
  TData = Awaited<ReturnType<typeof initiateCall>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CallRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CallRequest },
  TContext
> => {
  const mutationOptions = getInitiateCallMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Make a call to the specified phone number using Twilio.
 * @summary Initiate a Call
 */
export const initiateCallWithContext = (
  tenantUuid: string,
  callContextRequest: CallContextRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/calls/context`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: callContextRequest,
      signal,
    },
    options
  );
};

export const getInitiateCallWithContextMutationOptions = <
  TData = Awaited<ReturnType<typeof initiateCallWithContext>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CallContextRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["initiateCallWithContext"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof initiateCallWithContext>>,
    { tenantUuid: string; data: CallContextRequest }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return initiateCallWithContext(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CallContextRequest },
    TContext
  >;
};

export type InitiateCallWithContextMutationResult = NonNullable<
  Awaited<ReturnType<typeof initiateCallWithContext>>
>;
export type InitiateCallWithContextMutationBody = CallContextRequest;
export type InitiateCallWithContextMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Initiate a Call
 */
export const useInitiateCallWithContext = <
  TData = Awaited<ReturnType<typeof initiateCallWithContext>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CallContextRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CallContextRequest },
  TContext
> => {
  const mutationOptions = getInitiateCallWithContextMutationOptions(options);

  return useMutation(mutationOptions);
};
