/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type OutreachType = (typeof OutreachType)[keyof typeof OutreachType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutreachType = {
  blast: "blast",
  smart_blast: "smart_blast",
  smart_trigger: "smart_trigger",
} as const;
