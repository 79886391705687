/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  GetTechnicianResponseData,
  GetTechniciansParams,
  GetTechniciansResponse,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Technicians
 */
export const getTechnicians = (
  tenantUuid: string,
  params?: GetTechniciansParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetTechniciansResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/technicians`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetTechniciansQueryKey = (
  tenantUuid: string,
  params?: GetTechniciansParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/technicians`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetTechniciansQueryOptions = <
  TData = Awaited<ReturnType<typeof getTechnicians>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetTechniciansParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTechnicians>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTechniciansQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTechnicians>>> = ({
    signal,
  }) => getTechnicians(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTechnicians>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTechniciansQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTechnicians>>
>;
export type GetTechniciansQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Technicians
 */

export function useGetTechnicians<
  TData = Awaited<ReturnType<typeof getTechnicians>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetTechniciansParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTechnicians>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTechniciansQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Technician
 */
export const getTechnician = (
  tenantUuid: string,
  technicianUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetTechnicianResponseData>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/technician/${technicianUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTechnicianQueryKey = (
  tenantUuid: string,
  technicianUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/technician/${technicianUuid}`,
  ] as const;
};

export const getGetTechnicianQueryOptions = <
  TData = Awaited<ReturnType<typeof getTechnician>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  technicianUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTechnician>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTechnicianQueryKey(tenantUuid, technicianUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTechnician>>> = ({
    signal,
  }) => getTechnician(tenantUuid, technicianUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && technicianUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTechnician>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTechnicianQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTechnician>>
>;
export type GetTechnicianQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Technician
 */

export function useGetTechnician<
  TData = Awaited<ReturnType<typeof getTechnician>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  technicianUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTechnician>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTechnicianQueryOptions(
    tenantUuid,
    technicianUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
