/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type LeadType = (typeof LeadType)[keyof typeof LeadType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeadType = {
  customer: "customer",
} as const;
