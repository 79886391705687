/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type ConversationMessageRole =
  (typeof ConversationMessageRole)[keyof typeof ConversationMessageRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConversationMessageRole = {
  system: "system",
  assistant: "assistant",
  user: "user",
} as const;
