/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostCompetitionsWithEntriesSchema,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Email Wrapup Competitions
 */
export const emailWrapupCompetitions = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionsWithEntriesSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getEmailWrapupCompetitionsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/competitions`] as const;
};

export const getEmailWrapupCompetitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof emailWrapupCompetitions>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof emailWrapupCompetitions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEmailWrapupCompetitionsQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof emailWrapupCompetitions>>
  > = ({ signal }) =>
    emailWrapupCompetitions(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof emailWrapupCompetitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EmailWrapupCompetitionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof emailWrapupCompetitions>>
>;
export type EmailWrapupCompetitionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Email Wrapup Competitions
 */

export function useEmailWrapupCompetitions<
  TData = Awaited<ReturnType<typeof emailWrapupCompetitions>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof emailWrapupCompetitions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getEmailWrapupCompetitionsQueryOptions(
    tenantUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
