/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionPrizeWinType =
  (typeof CompetitionPrizeWinType)[keyof typeof CompetitionPrizeWinType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionPrizeWinType = {
  raffle: "raffle",
  top_n: "top_n",
} as const;
