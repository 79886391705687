/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type HandlerVariablePointsAwardSource =
  (typeof HandlerVariablePointsAwardSource)[keyof typeof HandlerVariablePointsAwardSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HandlerVariablePointsAwardSource = {
  invoice_total: "invoice_total",
  job_total: "job_total",
  item_total: "item_total",
} as const;
