/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ConversationMessageSchema,
  ConversationSchema,
  ConversationUpdateAiToggleResultSchema,
  HTTPValidationError,
  SearchConversationMessagesParams,
  SendConversationMessageParams,
  UpdateAiToggleParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Conversation Messages
 */
export const searchConversationMessages = (
  tenantUuid: string,
  conversationUuid: string,
  params?: SearchConversationMessagesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ConversationSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/conversations/${conversationUuid}/messages/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchConversationMessagesQueryKey = (
  tenantUuid: string,
  conversationUuid: string,
  params?: SearchConversationMessagesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/conversations/${conversationUuid}/messages/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchConversationMessagesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchConversationMessages>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  conversationUuid: string,
  params?: SearchConversationMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchConversationMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchConversationMessagesQueryKey(tenantUuid, conversationUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchConversationMessages>>
  > = ({ signal }) =>
    searchConversationMessages(
      tenantUuid,
      conversationUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && conversationUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchConversationMessages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchConversationMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchConversationMessages>>
>;
export type SearchConversationMessagesQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search Conversation Messages
 */

export function useSearchConversationMessages<
  TData = Awaited<ReturnType<typeof searchConversationMessages>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  conversationUuid: string,
  params?: SearchConversationMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchConversationMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchConversationMessagesQueryOptions(
    tenantUuid,
    conversationUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Ai Toggle
 */
export const updateAiToggle = (
  tenantUuid: string,
  conversationUuid: string,
  params: UpdateAiToggleParams,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<ConversationUpdateAiToggleResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/conversations/${conversationUuid}/ai_toggle`,
      method: "PUT",
      params,
    },
    options
  );
};

export const getUpdateAiToggleMutationOptions = <
  TData = Awaited<ReturnType<typeof updateAiToggle>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: UpdateAiToggleParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateAiToggle"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAiToggle>>,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: UpdateAiToggleParams;
    }
  > = (props) => {
    const { tenantUuid, conversationUuid, params } = props ?? {};

    return updateAiToggle(tenantUuid, conversationUuid, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: UpdateAiToggleParams;
    },
    TContext
  >;
};

export type UpdateAiToggleMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAiToggle>>
>;

export type UpdateAiToggleMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Ai Toggle
 */
export const useUpdateAiToggle = <
  TData = Awaited<ReturnType<typeof updateAiToggle>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: UpdateAiToggleParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    conversationUuid: string;
    params: UpdateAiToggleParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateAiToggleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Conversation Message
 */
export const sendConversationMessage = (
  tenantUuid: string,
  conversationUuid: string,
  params: SendConversationMessageParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ConversationMessageSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/conversations/${conversationUuid}/send_message`,
      method: "POST",
      params,
      signal,
    },
    options
  );
};

export const getSendConversationMessageMutationOptions = <
  TData = Awaited<ReturnType<typeof sendConversationMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: SendConversationMessageParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendConversationMessage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendConversationMessage>>,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: SendConversationMessageParams;
    }
  > = (props) => {
    const { tenantUuid, conversationUuid, params } = props ?? {};

    return sendConversationMessage(
      tenantUuid,
      conversationUuid,
      params,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: SendConversationMessageParams;
    },
    TContext
  >;
};

export type SendConversationMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendConversationMessage>>
>;

export type SendConversationMessageMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Send Conversation Message
 */
export const useSendConversationMessage = <
  TData = Awaited<ReturnType<typeof sendConversationMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      conversationUuid: string;
      params: SendConversationMessageParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    conversationUuid: string;
    params: SendConversationMessageParams;
  },
  TContext
> => {
  const mutationOptions = getSendConversationMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
