/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionPointsType =
  (typeof CompetitionPointsType)[keyof typeof CompetitionPointsType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionPointsType = {
  cumulative: "cumulative",
  average: "average",
  fixed: "fixed",
} as const;
