/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerType = {
  Commercial: "Commercial",
  Residential: "Residential",
} as const;
