/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type DevicePlatform =
  (typeof DevicePlatform)[keyof typeof DevicePlatform];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DevicePlatform = {
  other: "other",
  ios: "ios",
  android: "android",
} as const;
