/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost,
  BoostCompetitionCreateSchema,
  BoostCompetitionDetailsSchema,
  BoostCompetitionEligibleEntrantSearchResultSchema,
  BoostCompetitionEligibleFollowerSearchResultSchema,
  BoostCompetitionEntryDetailsWithRankSchema,
  BoostCompetitionEntrySearchResultSchema,
  BoostCompetitionFollowSchema,
  BoostCompetitionLineItemsSearchResultSchema,
  BoostCompetitionMessage,
  BoostCompetitionMessageResult,
  BoostCompetitionSchema,
  BoostCompetitionUpdateSchema,
  BoostCompetitionsSearchResultSchema,
  BoostMyCompetitionSearchResultSchema,
  BoostTeamCompetitionEntrySchema,
  BoostTeamCompetitionEntrySearchResultSchema,
  BoostUserCompetitionEntrySearchResultSchema,
  BulkAddUsersToCompetition200Item,
  ChangeTeamForUserEntry200,
  ChangeUserEntryTeamSchema,
  CompetitionCloneSchema,
  CompetitionEntryUUIDList,
  CompetitionFollowUuidList,
  CompetitionTeamEntryCreateSchema,
  CompetitionTeamEntryUpdateSchema,
  CompetitionUploadValidationResult,
  DeleteCompetitionEntryLineItem200,
  EvaluationReportSearchResultSchema,
  FeedItemBaseSchema,
  FeedItemReactionCreateSchema,
  FeedItemReplySchema,
  FeedItemReplyUpsertSchema,
  FeedReplySearchResultSchema,
  FeedSchema,
  GetCompetitionEntryLineItemsParams,
  GetCompetitionFeedForUserParams,
  GetCompetitionFeedParams,
  GetCompetitionParams,
  GetCompetitionsParams,
  GetCurrentUserCompetitionEntry200,
  GetEvaluationReportsParams,
  GetSingleCompetitionFeedParams,
  HTTPValidationError,
  IncrementCompetitionEntry200,
  IncrementCompetitionEntrySchema,
  SearchCompetitionEligibleEntrantsParams,
  SearchCompetitionEligibleFollowersParams,
  SearchCompetitionEntriesParams,
  SearchFeedItemRepliesParams,
  SearchMyCompetitionsParams,
  SearchTeamCompetitionEntriesParams,
  SearchUserCompetitionEntriesParams,
  UserUUIDList,
  ValidatedCompetitionUpload,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Competitions
 */
export const getCompetitions = (
  tenantUuid: string,
  params?: GetCompetitionsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionsSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompetitionsQueryKey = (
  tenantUuid: string,
  params?: GetCompetitionsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompetitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitions>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCompetitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompetitionsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompetitions>>> = ({
    signal,
  }) => getCompetitions(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitions>>
>;
export type GetCompetitionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Competitions
 */

export function useGetCompetitions<
  TData = Awaited<ReturnType<typeof getCompetitions>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCompetitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search My Competitions
 */
export const searchMyCompetitions = (
  tenantUuid: string,
  params?: SearchMyCompetitionsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostMyCompetitionSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/search/me`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchMyCompetitionsQueryKey = (
  tenantUuid: string,
  params?: SearchMyCompetitionsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/search/me`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchMyCompetitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchMyCompetitions>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchMyCompetitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchMyCompetitions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchMyCompetitionsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchMyCompetitions>>
  > = ({ signal }) =>
    searchMyCompetitions(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchMyCompetitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchMyCompetitionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchMyCompetitions>>
>;
export type SearchMyCompetitionsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search My Competitions
 */

export function useSearchMyCompetitions<
  TData = Awaited<ReturnType<typeof searchMyCompetitions>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchMyCompetitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchMyCompetitions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchMyCompetitionsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Competition
 */
export const createCompetition = (
  tenantUuid: string,
  boostCompetitionCreateSchema: BoostCompetitionCreateSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionDetailsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostCompetitionCreateSchema,
      signal,
    },
    options
  );
};

export const getCreateCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof createCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostCompetitionCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCompetition>>,
    { tenantUuid: string; data: BoostCompetitionCreateSchema }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createCompetition(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostCompetitionCreateSchema },
    TContext
  >;
};

export type CreateCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCompetition>>
>;
export type CreateCompetitionMutationBody = BoostCompetitionCreateSchema;
export type CreateCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Competition
 */
export const useCreateCompetition = <
  TData = Awaited<ReturnType<typeof createCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostCompetitionCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: BoostCompetitionCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Competition
 */
export const getCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  params?: GetCompetitionParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionDetailsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompetitionQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: GetCompetitionParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompetitionQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetition>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: GetCompetitionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetition>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCompetitionQueryKey(tenantUuid, competitionUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompetition>>> = ({
    signal,
  }) =>
    getCompetition(tenantUuid, competitionUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetition>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetition>>
>;
export type GetCompetitionQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition
 */

export function useGetCompetition<
  TData = Awaited<ReturnType<typeof getCompetition>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: GetCompetitionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetition>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Competition
 */
export const updateCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  boostCompetitionUpdateSchema: BoostCompetitionUpdateSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostCompetitionDetailsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: boostCompetitionUpdateSchema,
    },
    options
  );
};

export const getUpdateCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionUpdateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCompetition>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionUpdateSchema;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return updateCompetition(tenantUuid, competitionUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionUpdateSchema;
    },
    TContext
  >;
};

export type UpdateCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCompetition>>
>;
export type UpdateCompetitionMutationBody = BoostCompetitionUpdateSchema;
export type UpdateCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Competition
 */
export const useUpdateCompetition = <
  TData = Awaited<ReturnType<typeof updateCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionUpdateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: BoostCompetitionUpdateSchema;
  },
  TContext
> => {
  const mutationOptions = getUpdateCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Competition
 */
export const deleteCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["deleteCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCompetition>>,
    { tenantUuid: string; competitionUuid: string }
  > = (props) => {
    const { tenantUuid, competitionUuid } = props ?? {};

    return deleteCompetition(tenantUuid, competitionUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
};

export type DeleteCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCompetition>>
>;

export type DeleteCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Competition
 */
export const useDeleteCompetition = <
  TData = Awaited<ReturnType<typeof deleteCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string },
  TContext
> => {
  const mutationOptions = getDeleteCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Competition Eligible Entrants
 */
export const searchCompetitionEligibleEntrants = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleEntrantsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionEligibleEntrantSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/eligible-entrants`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchCompetitionEligibleEntrantsQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleEntrantsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/eligible-entrants`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchCompetitionEligibleEntrantsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleEntrantsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchCompetitionEligibleEntrantsQueryKey(
      tenantUuid,
      competitionUuid,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>
  > = ({ signal }) =>
    searchCompetitionEligibleEntrants(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchCompetitionEligibleEntrantsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>
>;
export type SearchCompetitionEligibleEntrantsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search Competition Eligible Entrants
 */

export function useSearchCompetitionEligibleEntrants<
  TData = Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleEntrantsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionEligibleEntrants>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchCompetitionEligibleEntrantsQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search Competition Eligible Followers
 */
export const searchCompetitionEligibleFollowers = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleFollowersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionEligibleFollowerSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/eligible-followers`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchCompetitionEligibleFollowersQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleFollowersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/eligible-followers`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchCompetitionEligibleFollowersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleFollowersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchCompetitionEligibleFollowersQueryKey(
      tenantUuid,
      competitionUuid,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>
  > = ({ signal }) =>
    searchCompetitionEligibleFollowers(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchCompetitionEligibleFollowersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>
>;
export type SearchCompetitionEligibleFollowersQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search Competition Eligible Followers
 */

export function useSearchCompetitionEligibleFollowers<
  TData = Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEligibleFollowersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionEligibleFollowers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchCompetitionEligibleFollowersQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Publish Competition
 */
export const publishCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionDetailsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/publish`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getPublishCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof publishCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["publishCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishCompetition>>,
    { tenantUuid: string; competitionUuid: string }
  > = (props) => {
    const { tenantUuid, competitionUuid } = props ?? {};

    return publishCompetition(tenantUuid, competitionUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
};

export type PublishCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishCompetition>>
>;

export type PublishCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Publish Competition
 */
export const usePublishCompetition = <
  TData = Awaited<ReturnType<typeof publishCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string },
  TContext
> => {
  const mutationOptions = getPublishCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Start Competition
 */
export const startCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/start`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getStartCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof startCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["startCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startCompetition>>,
    { tenantUuid: string; competitionUuid: string }
  > = (props) => {
    const { tenantUuid, competitionUuid } = props ?? {};

    return startCompetition(tenantUuid, competitionUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
};

export type StartCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof startCompetition>>
>;

export type StartCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Start Competition
 */
export const useStartCompetition = <
  TData = Awaited<ReturnType<typeof startCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string },
  TContext
> => {
  const mutationOptions = getStartCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Finalize Competition
 */
export const finalizeCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/finalize`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getFinalizeCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof finalizeCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["finalizeCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof finalizeCompetition>>,
    { tenantUuid: string; competitionUuid: string }
  > = (props) => {
    const { tenantUuid, competitionUuid } = props ?? {};

    return finalizeCompetition(tenantUuid, competitionUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
};

export type FinalizeCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof finalizeCompetition>>
>;

export type FinalizeCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Finalize Competition
 */
export const useFinalizeCompetition = <
  TData = Awaited<ReturnType<typeof finalizeCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string },
  TContext
> => {
  const mutationOptions = getFinalizeCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Complete Competition
 */
export const completeCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/complete`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getCompleteCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof completeCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["completeCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completeCompetition>>,
    { tenantUuid: string; competitionUuid: string }
  > = (props) => {
    const { tenantUuid, competitionUuid } = props ?? {};

    return completeCompetition(tenantUuid, competitionUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
};

export type CompleteCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof completeCompetition>>
>;

export type CompleteCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Complete Competition
 */
export const useCompleteCompetition = <
  TData = Awaited<ReturnType<typeof completeCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string },
  TContext
> => {
  const mutationOptions = getCompleteCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Announce Competition
 */
export const announceCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionMessageResult>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/announce`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getAnnounceCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof announceCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["announceCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof announceCompetition>>,
    { tenantUuid: string; competitionUuid: string }
  > = (props) => {
    const { tenantUuid, competitionUuid } = props ?? {};

    return announceCompetition(tenantUuid, competitionUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
};

export type AnnounceCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof announceCompetition>>
>;

export type AnnounceCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Announce Competition
 */
export const useAnnounceCompetition = <
  TData = Awaited<ReturnType<typeof announceCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string },
  TContext
> => {
  const mutationOptions = getAnnounceCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Bulk Add Users To Competition
 */
export const bulkAddUsersToCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  userUUIDList: UserUUIDList,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BulkAddUsersToCompetition200Item[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/enroll`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userUUIDList,
      signal,
    },
    options
  );
};

export const getBulkAddUsersToCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof bulkAddUsersToCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["bulkAddUsersToCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkAddUsersToCompetition>>,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return bulkAddUsersToCompetition(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
    TContext
  >;
};

export type BulkAddUsersToCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkAddUsersToCompetition>>
>;
export type BulkAddUsersToCompetitionMutationBody = UserUUIDList;
export type BulkAddUsersToCompetitionMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Bulk Add Users To Competition
 */
export const useBulkAddUsersToCompetition = <
  TData = Awaited<ReturnType<typeof bulkAddUsersToCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
  TContext
> => {
  const mutationOptions = getBulkAddUsersToCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Bulk Add Followers To Competition
 */
export const bulkAddFollowersToCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  userUUIDList: UserUUIDList,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionFollowSchema[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/add-followers`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userUUIDList,
      signal,
    },
    options
  );
};

export const getBulkAddFollowersToCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof bulkAddFollowersToCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["bulkAddFollowersToCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkAddFollowersToCompetition>>,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return bulkAddFollowersToCompetition(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
    TContext
  >;
};

export type BulkAddFollowersToCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkAddFollowersToCompetition>>
>;
export type BulkAddFollowersToCompetitionMutationBody = UserUUIDList;
export type BulkAddFollowersToCompetitionMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Bulk Add Followers To Competition
 */
export const useBulkAddFollowersToCompetition = <
  TData = Awaited<ReturnType<typeof bulkAddFollowersToCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string; data: UserUUIDList },
  TContext
> => {
  const mutationOptions =
    getBulkAddFollowersToCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Competition Message
 */
export const sendCompetitionMessage = (
  tenantUuid: string,
  competitionUuid: string,
  boostCompetitionMessage: BoostCompetitionMessage,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionMessageResult>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/message`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostCompetitionMessage,
      signal,
    },
    options
  );
};

export const getSendCompetitionMessageMutationOptions = <
  TData = Awaited<ReturnType<typeof sendCompetitionMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionMessage;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendCompetitionMessage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendCompetitionMessage>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionMessage;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return sendCompetitionMessage(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionMessage;
    },
    TContext
  >;
};

export type SendCompetitionMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendCompetitionMessage>>
>;
export type SendCompetitionMessageMutationBody = BoostCompetitionMessage;
export type SendCompetitionMessageMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Send Competition Message
 */
export const useSendCompetitionMessage = <
  TData = Awaited<ReturnType<typeof sendCompetitionMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BoostCompetitionMessage;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: BoostCompetitionMessage;
  },
  TContext
> => {
  const mutationOptions = getSendCompetitionMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Competition Upload Template
 */
export const getCompetitionUploadTemplate = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/download-template`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCompetitionUploadTemplateQueryKey = (
  tenantUuid: string,
  competitionUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/download-template`,
  ] as const;
};

export const getGetCompetitionUploadTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitionUploadTemplate>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionUploadTemplate>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCompetitionUploadTemplateQueryKey(tenantUuid, competitionUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompetitionUploadTemplate>>
  > = ({ signal }) =>
    getCompetitionUploadTemplate(
      tenantUuid,
      competitionUuid,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitionUploadTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionUploadTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitionUploadTemplate>>
>;
export type GetCompetitionUploadTemplateQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition Upload Template
 */

export function useGetCompetitionUploadTemplate<
  TData = Awaited<ReturnType<typeof getCompetitionUploadTemplate>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionUploadTemplate>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionUploadTemplateQueryOptions(
    tenantUuid,
    competitionUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Team Competition Entry
 */
export const createTeamCompetitionEntry = (
  tenantUuid: string,
  competitionUuid: string,
  competitionTeamEntryCreateSchema: CompetitionTeamEntryCreateSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostTeamCompetitionEntrySchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: competitionTeamEntryCreateSchema,
      signal,
    },
    options
  );
};

export const getCreateTeamCompetitionEntryMutationOptions = <
  TData = Awaited<ReturnType<typeof createTeamCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionTeamEntryCreateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createTeamCompetitionEntry"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTeamCompetitionEntry>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionTeamEntryCreateSchema;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return createTeamCompetitionEntry(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionTeamEntryCreateSchema;
    },
    TContext
  >;
};

export type CreateTeamCompetitionEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTeamCompetitionEntry>>
>;
export type CreateTeamCompetitionEntryMutationBody =
  CompetitionTeamEntryCreateSchema;
export type CreateTeamCompetitionEntryMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Team Competition Entry
 */
export const useCreateTeamCompetitionEntry = <
  TData = Awaited<ReturnType<typeof createTeamCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionTeamEntryCreateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: CompetitionTeamEntryCreateSchema;
  },
  TContext
> => {
  const mutationOptions = getCreateTeamCompetitionEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Competition Entries
 */
export const searchCompetitionEntries = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEntriesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionEntrySearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchCompetitionEntriesQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEntriesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchCompetitionEntriesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchCompetitionEntriesQueryKey(tenantUuid, competitionUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchCompetitionEntries>>
  > = ({ signal }) =>
    searchCompetitionEntries(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchCompetitionEntries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchCompetitionEntriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchCompetitionEntries>>
>;
export type SearchCompetitionEntriesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Competition Entries
 */

export function useSearchCompetitionEntries<
  TData = Awaited<ReturnType<typeof searchCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchCompetitionEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchCompetitionEntriesQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Validate Competition Entries Upload
 */
export const validateCompetitionEntriesUpload = (
  tenantUuid: string,
  competitionUuid: string,
  bodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost: BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  const formData = new FormData();
  formData.append(
    "data",
    bodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost.data
  );

  return axiosInstance<CompetitionUploadValidationResult>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/actions/validate-upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      signal,
    },
    options
  );
};

export const getValidateCompetitionEntriesUploadMutationOptions = <
  TData = Awaited<ReturnType<typeof validateCompetitionEntriesUpload>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["validateCompetitionEntriesUpload"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateCompetitionEntriesUpload>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return validateCompetitionEntriesUpload(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost;
    },
    TContext
  >;
};

export type ValidateCompetitionEntriesUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateCompetitionEntriesUpload>>
>;
export type ValidateCompetitionEntriesUploadMutationBody =
  BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost;
export type ValidateCompetitionEntriesUploadMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Validate Competition Entries Upload
 */
export const useValidateCompetitionEntriesUpload = <
  TData = Awaited<ReturnType<typeof validateCompetitionEntriesUpload>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: BodyCompetitionResourceUserValidateCompetitionEntriesUploadApiV1TenantsTenantUuidBoostCompetitionsCompetitionUuidEntriesActionsValidateUploadPost;
  },
  TContext
> => {
  const mutationOptions =
    getValidateCompetitionEntriesUploadMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Apply Competition Entries Upload
 */
export const applyCompetitionEntriesUpload = (
  tenantUuid: string,
  competitionUuid: string,
  validatedCompetitionUpload: ValidatedCompetitionUpload,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionEntrySearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/actions/apply-upload`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: validatedCompetitionUpload,
      signal,
    },
    options
  );
};

export const getApplyCompetitionEntriesUploadMutationOptions = <
  TData = Awaited<ReturnType<typeof applyCompetitionEntriesUpload>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: ValidatedCompetitionUpload;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["applyCompetitionEntriesUpload"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof applyCompetitionEntriesUpload>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: ValidatedCompetitionUpload;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return applyCompetitionEntriesUpload(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: ValidatedCompetitionUpload;
    },
    TContext
  >;
};

export type ApplyCompetitionEntriesUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof applyCompetitionEntriesUpload>>
>;
export type ApplyCompetitionEntriesUploadMutationBody =
  ValidatedCompetitionUpload;
export type ApplyCompetitionEntriesUploadMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Apply Competition Entries Upload
 */
export const useApplyCompetitionEntriesUpload = <
  TData = Awaited<ReturnType<typeof applyCompetitionEntriesUpload>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: ValidatedCompetitionUpload;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: ValidatedCompetitionUpload;
  },
  TContext
> => {
  const mutationOptions =
    getApplyCompetitionEntriesUploadMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Team Competition Entries
 */
export const searchTeamCompetitionEntries = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchTeamCompetitionEntriesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostTeamCompetitionEntrySearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/search/teams`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchTeamCompetitionEntriesQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchTeamCompetitionEntriesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/search/teams`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchTeamCompetitionEntriesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchTeamCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchTeamCompetitionEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTeamCompetitionEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchTeamCompetitionEntriesQueryKey(
      tenantUuid,
      competitionUuid,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchTeamCompetitionEntries>>
  > = ({ signal }) =>
    searchTeamCompetitionEntries(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchTeamCompetitionEntries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchTeamCompetitionEntriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchTeamCompetitionEntries>>
>;
export type SearchTeamCompetitionEntriesQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search Team Competition Entries
 */

export function useSearchTeamCompetitionEntries<
  TData = Awaited<ReturnType<typeof searchTeamCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchTeamCompetitionEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTeamCompetitionEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchTeamCompetitionEntriesQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search User Competition Entries
 */
export const searchUserCompetitionEntries = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchUserCompetitionEntriesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserCompetitionEntrySearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/search/users`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchUserCompetitionEntriesQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchUserCompetitionEntriesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/search/users`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchUserCompetitionEntriesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchUserCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchUserCompetitionEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchUserCompetitionEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchUserCompetitionEntriesQueryKey(
      tenantUuid,
      competitionUuid,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchUserCompetitionEntries>>
  > = ({ signal }) =>
    searchUserCompetitionEntries(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchUserCompetitionEntries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchUserCompetitionEntriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchUserCompetitionEntries>>
>;
export type SearchUserCompetitionEntriesQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search User Competition Entries
 */

export function useSearchUserCompetitionEntries<
  TData = Awaited<ReturnType<typeof searchUserCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: SearchUserCompetitionEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchUserCompetitionEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchUserCompetitionEntriesQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Current User Competition Entry
 */
export const getCurrentUserCompetitionEntry = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetCurrentUserCompetitionEntry200>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/me`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCurrentUserCompetitionEntryQueryKey = (
  tenantUuid: string,
  competitionUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/me`,
  ] as const;
};

export const getGetCurrentUserCompetitionEntryQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCurrentUserCompetitionEntryQueryKey(tenantUuid, competitionUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>
  > = ({ signal }) =>
    getCurrentUserCompetitionEntry(
      tenantUuid,
      competitionUuid,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentUserCompetitionEntryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>
>;
export type GetCurrentUserCompetitionEntryQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Current User Competition Entry
 */

export function useGetCurrentUserCompetitionEntry<
  TData = Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentUserCompetitionEntry>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCurrentUserCompetitionEntryQueryOptions(
    tenantUuid,
    competitionUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Competition Entry
 */
export const getCompetitionEntry = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionEntryDetailsWithRankSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCompetitionEntryQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}`,
  ] as const;
};

export const getGetCompetitionEntryQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionEntry>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCompetitionEntryQueryKey(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompetitionEntry>>
  > = ({ signal }) =>
    getCompetitionEntry(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid && competitionEntryUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitionEntry>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionEntryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitionEntry>>
>;
export type GetCompetitionEntryQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition Entry
 */

export function useGetCompetitionEntry<
  TData = Awaited<ReturnType<typeof getCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionEntry>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionEntryQueryOptions(
    tenantUuid,
    competitionUuid,
    competitionEntryUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Team Competition Entry
 */
export const updateTeamCompetitionEntry = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  competitionTeamEntryUpdateSchema: CompetitionTeamEntryUpdateSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostTeamCompetitionEntrySchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: competitionTeamEntryUpdateSchema,
    },
    options
  );
};

export const getUpdateTeamCompetitionEntryMutationOptions = <
  TData = Awaited<ReturnType<typeof updateTeamCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: CompetitionTeamEntryUpdateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateTeamCompetitionEntry"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTeamCompetitionEntry>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: CompetitionTeamEntryUpdateSchema;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, competitionEntryUuid, data } =
      props ?? {};

    return updateTeamCompetitionEntry(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: CompetitionTeamEntryUpdateSchema;
    },
    TContext
  >;
};

export type UpdateTeamCompetitionEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTeamCompetitionEntry>>
>;
export type UpdateTeamCompetitionEntryMutationBody =
  CompetitionTeamEntryUpdateSchema;
export type UpdateTeamCompetitionEntryMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Update Team Competition Entry
 */
export const useUpdateTeamCompetitionEntry = <
  TData = Awaited<ReturnType<typeof updateTeamCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: CompetitionTeamEntryUpdateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    competitionEntryUuid: string;
    data: CompetitionTeamEntryUpdateSchema;
  },
  TContext
> => {
  const mutationOptions = getUpdateTeamCompetitionEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Add Users To Team
 */
export const addUsersToTeam = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  userUUIDList: UserUUIDList,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompetitionEntryUUIDList>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}/actions/add-users-to-team`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userUUIDList,
      signal,
    },
    options
  );
};

export const getAddUsersToTeamMutationOptions = <
  TData = Awaited<ReturnType<typeof addUsersToTeam>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: UserUUIDList;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["addUsersToTeam"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUsersToTeam>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: UserUUIDList;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, competitionEntryUuid, data } =
      props ?? {};

    return addUsersToTeam(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: UserUUIDList;
    },
    TContext
  >;
};

export type AddUsersToTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof addUsersToTeam>>
>;
export type AddUsersToTeamMutationBody = UserUUIDList;
export type AddUsersToTeamMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Add Users To Team
 */
export const useAddUsersToTeam = <
  TData = Awaited<ReturnType<typeof addUsersToTeam>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: UserUUIDList;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    competitionEntryUuid: string;
    data: UserUUIDList;
  },
  TContext
> => {
  const mutationOptions = getAddUsersToTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Change Team For User Entry
 */
export const changeTeamForUserEntry = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  changeUserEntryTeamSchema: ChangeUserEntryTeamSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ChangeTeamForUserEntry200>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}/actions/change-team`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changeUserEntryTeamSchema,
      signal,
    },
    options
  );
};

export const getChangeTeamForUserEntryMutationOptions = <
  TData = Awaited<ReturnType<typeof changeTeamForUserEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: ChangeUserEntryTeamSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["changeTeamForUserEntry"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeTeamForUserEntry>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: ChangeUserEntryTeamSchema;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, competitionEntryUuid, data } =
      props ?? {};

    return changeTeamForUserEntry(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: ChangeUserEntryTeamSchema;
    },
    TContext
  >;
};

export type ChangeTeamForUserEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeTeamForUserEntry>>
>;
export type ChangeTeamForUserEntryMutationBody = ChangeUserEntryTeamSchema;
export type ChangeTeamForUserEntryMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Change Team For User Entry
 */
export const useChangeTeamForUserEntry = <
  TData = Awaited<ReturnType<typeof changeTeamForUserEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: ChangeUserEntryTeamSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    competitionEntryUuid: string;
    data: ChangeUserEntryTeamSchema;
  },
  TContext
> => {
  const mutationOptions = getChangeTeamForUserEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Increment Competition Entry
 */
export const incrementCompetitionEntry = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  incrementCompetitionEntrySchema: IncrementCompetitionEntrySchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<IncrementCompetitionEntry200>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}/actions/increment`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: incrementCompetitionEntrySchema,
      signal,
    },
    options
  );
};

export const getIncrementCompetitionEntryMutationOptions = <
  TData = Awaited<ReturnType<typeof incrementCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: IncrementCompetitionEntrySchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["incrementCompetitionEntry"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof incrementCompetitionEntry>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: IncrementCompetitionEntrySchema;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, competitionEntryUuid, data } =
      props ?? {};

    return incrementCompetitionEntry(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: IncrementCompetitionEntrySchema;
    },
    TContext
  >;
};

export type IncrementCompetitionEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof incrementCompetitionEntry>>
>;
export type IncrementCompetitionEntryMutationBody =
  IncrementCompetitionEntrySchema;
export type IncrementCompetitionEntryMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Increment Competition Entry
 */
export const useIncrementCompetitionEntry = <
  TData = Awaited<ReturnType<typeof incrementCompetitionEntry>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      data: IncrementCompetitionEntrySchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    competitionEntryUuid: string;
    data: IncrementCompetitionEntrySchema;
  },
  TContext
> => {
  const mutationOptions = getIncrementCompetitionEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Bulk Delete Competition Entries
 */
export const bulkDeleteCompetitionEntries = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUUIDList: CompetitionEntryUUIDList,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompetitionEntryUUIDList>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/actions/delete`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: competitionEntryUUIDList,
      signal,
    },
    options
  );
};

export const getBulkDeleteCompetitionEntriesMutationOptions = <
  TData = Awaited<ReturnType<typeof bulkDeleteCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionEntryUUIDList;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["bulkDeleteCompetitionEntries"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkDeleteCompetitionEntries>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionEntryUUIDList;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return bulkDeleteCompetitionEntries(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionEntryUUIDList;
    },
    TContext
  >;
};

export type BulkDeleteCompetitionEntriesMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkDeleteCompetitionEntries>>
>;
export type BulkDeleteCompetitionEntriesMutationBody = CompetitionEntryUUIDList;
export type BulkDeleteCompetitionEntriesMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Bulk Delete Competition Entries
 */
export const useBulkDeleteCompetitionEntries = <
  TData = Awaited<ReturnType<typeof bulkDeleteCompetitionEntries>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionEntryUUIDList;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: CompetitionEntryUUIDList;
  },
  TContext
> => {
  const mutationOptions =
    getBulkDeleteCompetitionEntriesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Competition Entry Line Items
 */
export const getCompetitionEntryLineItems = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  params?: GetCompetitionEntryLineItemsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionLineItemsSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}/line-items`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompetitionEntryLineItemsQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  params?: GetCompetitionEntryLineItemsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}/line-items`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompetitionEntryLineItemsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitionEntryLineItems>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  params?: GetCompetitionEntryLineItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionEntryLineItems>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCompetitionEntryLineItemsQueryKey(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompetitionEntryLineItems>>
  > = ({ signal }) =>
    getCompetitionEntryLineItems(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid && competitionEntryUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitionEntryLineItems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionEntryLineItemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitionEntryLineItems>>
>;
export type GetCompetitionEntryLineItemsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition Entry Line Items
 */

export function useGetCompetitionEntryLineItems<
  TData = Awaited<ReturnType<typeof getCompetitionEntryLineItems>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  params?: GetCompetitionEntryLineItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionEntryLineItems>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionEntryLineItemsQueryOptions(
    tenantUuid,
    competitionUuid,
    competitionEntryUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete Competition Entry Line Item
 */
export const deleteCompetitionEntryLineItem = (
  tenantUuid: string,
  competitionUuid: string,
  competitionEntryUuid: string,
  lineItemUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<DeleteCompetitionEntryLineItem200>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/entries/${competitionEntryUuid}/line-items/${lineItemUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteCompetitionEntryLineItemMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteCompetitionEntryLineItem>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      lineItemUuid: string;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["deleteCompetitionEntryLineItem"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCompetitionEntryLineItem>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      lineItemUuid: string;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, competitionEntryUuid, lineItemUuid } =
      props ?? {};

    return deleteCompetitionEntryLineItem(
      tenantUuid,
      competitionUuid,
      competitionEntryUuid,
      lineItemUuid,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      lineItemUuid: string;
    },
    TContext
  >;
};

export type DeleteCompetitionEntryLineItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCompetitionEntryLineItem>>
>;

export type DeleteCompetitionEntryLineItemMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete Competition Entry Line Item
 */
export const useDeleteCompetitionEntryLineItem = <
  TData = Awaited<ReturnType<typeof deleteCompetitionEntryLineItem>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      competitionEntryUuid: string;
      lineItemUuid: string;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    competitionEntryUuid: string;
    lineItemUuid: string;
  },
  TContext
> => {
  const mutationOptions =
    getDeleteCompetitionEntryLineItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Bulk Delete Competition Followers
 */
export const bulkDeleteCompetitionFollowers = (
  tenantUuid: string,
  competitionUuid: string,
  competitionFollowUuidList: CompetitionFollowUuidList,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompetitionFollowUuidList>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/followers/actions/delete`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: competitionFollowUuidList,
      signal,
    },
    options
  );
};

export const getBulkDeleteCompetitionFollowersMutationOptions = <
  TData = Awaited<ReturnType<typeof bulkDeleteCompetitionFollowers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionFollowUuidList;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["bulkDeleteCompetitionFollowers"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkDeleteCompetitionFollowers>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionFollowUuidList;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return bulkDeleteCompetitionFollowers(
      tenantUuid,
      competitionUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionFollowUuidList;
    },
    TContext
  >;
};

export type BulkDeleteCompetitionFollowersMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkDeleteCompetitionFollowers>>
>;
export type BulkDeleteCompetitionFollowersMutationBody =
  CompetitionFollowUuidList;
export type BulkDeleteCompetitionFollowersMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Bulk Delete Competition Followers
 */
export const useBulkDeleteCompetitionFollowers = <
  TData = Awaited<ReturnType<typeof bulkDeleteCompetitionFollowers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionFollowUuidList;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    competitionUuid: string;
    data: CompetitionFollowUuidList;
  },
  TContext
> => {
  const mutationOptions =
    getBulkDeleteCompetitionFollowersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Competition Feed
 */
export const getCompetitionFeed = (
  tenantUuid: string,
  params?: GetCompetitionFeedParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeedSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/me`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompetitionFeedQueryKey = (
  tenantUuid: string,
  params?: GetCompetitionFeedParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/me`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompetitionFeedQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitionFeed>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCompetitionFeedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionFeed>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCompetitionFeedQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompetitionFeed>>
  > = ({ signal }) =>
    getCompetitionFeed(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitionFeed>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionFeedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitionFeed>>
>;
export type GetCompetitionFeedQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition Feed
 */

export function useGetCompetitionFeed<
  TData = Awaited<ReturnType<typeof getCompetitionFeed>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCompetitionFeedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionFeed>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionFeedQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Competition Feed For User
 */
export const getCompetitionFeedForUser = (
  tenantUuid: string,
  params?: GetCompetitionFeedForUserParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeedSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCompetitionFeedForUserQueryKey = (
  tenantUuid: string,
  params?: GetCompetitionFeedForUserParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompetitionFeedForUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitionFeedForUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCompetitionFeedForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionFeedForUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCompetitionFeedForUserQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompetitionFeedForUser>>
  > = ({ signal }) =>
    getCompetitionFeedForUser(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitionFeedForUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionFeedForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitionFeedForUser>>
>;
export type GetCompetitionFeedForUserQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition Feed For User
 */

export function useGetCompetitionFeedForUser<
  TData = Awaited<ReturnType<typeof getCompetitionFeedForUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCompetitionFeedForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionFeedForUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionFeedForUserQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary React To Feed Item
 */
export const reactToFeedItem = (
  tenantUuid: string,
  feedItemUuid: string,
  feedItemReactionCreateSchema: FeedItemReactionCreateSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeedItemBaseSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/${feedItemUuid}/actions/react`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: feedItemReactionCreateSchema,
      signal,
    },
    options
  );
};

export const getReactToFeedItemMutationOptions = <
  TData = Awaited<ReturnType<typeof reactToFeedItem>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReactionCreateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["reactToFeedItem"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reactToFeedItem>>,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReactionCreateSchema;
    }
  > = (props) => {
    const { tenantUuid, feedItemUuid, data } = props ?? {};

    return reactToFeedItem(tenantUuid, feedItemUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReactionCreateSchema;
    },
    TContext
  >;
};

export type ReactToFeedItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof reactToFeedItem>>
>;
export type ReactToFeedItemMutationBody = FeedItemReactionCreateSchema;
export type ReactToFeedItemMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary React To Feed Item
 */
export const useReactToFeedItem = <
  TData = Awaited<ReturnType<typeof reactToFeedItem>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReactionCreateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    feedItemUuid: string;
    data: FeedItemReactionCreateSchema;
  },
  TContext
> => {
  const mutationOptions = getReactToFeedItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Feed Item Reaction
 */
export const deleteFeedItemReaction = (
  tenantUuid: string,
  feedItemUuid: string,
  feedItemReactionUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/${feedItemUuid}/reactions/${feedItemReactionUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteFeedItemReactionMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteFeedItemReaction>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; feedItemUuid: string; feedItemReactionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["deleteFeedItemReaction"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFeedItemReaction>>,
    { tenantUuid: string; feedItemUuid: string; feedItemReactionUuid: string }
  > = (props) => {
    const { tenantUuid, feedItemUuid, feedItemReactionUuid } = props ?? {};

    return deleteFeedItemReaction(
      tenantUuid,
      feedItemUuid,
      feedItemReactionUuid,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; feedItemUuid: string; feedItemReactionUuid: string },
    TContext
  >;
};

export type DeleteFeedItemReactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFeedItemReaction>>
>;

export type DeleteFeedItemReactionMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Delete Feed Item Reaction
 */
export const useDeleteFeedItemReaction = <
  TData = Awaited<ReturnType<typeof deleteFeedItemReaction>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; feedItemUuid: string; feedItemReactionUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; feedItemUuid: string; feedItemReactionUuid: string },
  TContext
> => {
  const mutationOptions = getDeleteFeedItemReactionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Reply To Feed Item
 */
export const replyToFeedItem = (
  tenantUuid: string,
  feedItemUuid: string,
  feedItemReplyUpsertSchema: FeedItemReplyUpsertSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeedItemReplySchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/${feedItemUuid}/replies`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: feedItemReplyUpsertSchema,
      signal,
    },
    options
  );
};

export const getReplyToFeedItemMutationOptions = <
  TData = Awaited<ReturnType<typeof replyToFeedItem>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReplyUpsertSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["replyToFeedItem"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replyToFeedItem>>,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReplyUpsertSchema;
    }
  > = (props) => {
    const { tenantUuid, feedItemUuid, data } = props ?? {};

    return replyToFeedItem(tenantUuid, feedItemUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReplyUpsertSchema;
    },
    TContext
  >;
};

export type ReplyToFeedItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof replyToFeedItem>>
>;
export type ReplyToFeedItemMutationBody = FeedItemReplyUpsertSchema;
export type ReplyToFeedItemMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Reply To Feed Item
 */
export const useReplyToFeedItem = <
  TData = Awaited<ReturnType<typeof replyToFeedItem>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      feedItemUuid: string;
      data: FeedItemReplyUpsertSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; feedItemUuid: string; data: FeedItemReplyUpsertSchema },
  TContext
> => {
  const mutationOptions = getReplyToFeedItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Feed Item Replies
 */
export const searchFeedItemReplies = (
  tenantUuid: string,
  feedItemUuid: string,
  params?: SearchFeedItemRepliesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeedReplySearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/${feedItemUuid}/replies`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchFeedItemRepliesQueryKey = (
  tenantUuid: string,
  feedItemUuid: string,
  params?: SearchFeedItemRepliesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/feed-items/${feedItemUuid}/replies`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchFeedItemRepliesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFeedItemReplies>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  feedItemUuid: string,
  params?: SearchFeedItemRepliesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFeedItemReplies>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchFeedItemRepliesQueryKey(tenantUuid, feedItemUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchFeedItemReplies>>
  > = ({ signal }) =>
    searchFeedItemReplies(
      tenantUuid,
      feedItemUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && feedItemUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchFeedItemReplies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchFeedItemRepliesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFeedItemReplies>>
>;
export type SearchFeedItemRepliesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Feed Item Replies
 */

export function useSearchFeedItemReplies<
  TData = Awaited<ReturnType<typeof searchFeedItemReplies>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  feedItemUuid: string,
  params?: SearchFeedItemRepliesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFeedItemReplies>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchFeedItemRepliesQueryOptions(
    tenantUuid,
    feedItemUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Single Competition Feed
 */
export const getSingleCompetitionFeed = (
  tenantUuid: string,
  competitionUuid: string,
  params?: GetSingleCompetitionFeedParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeedSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/feed-items`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetSingleCompetitionFeedQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: GetSingleCompetitionFeedParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/feed-items`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetSingleCompetitionFeedQueryOptions = <
  TData = Awaited<ReturnType<typeof getSingleCompetitionFeed>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: GetSingleCompetitionFeedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSingleCompetitionFeed>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSingleCompetitionFeedQueryKey(tenantUuid, competitionUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSingleCompetitionFeed>>
  > = ({ signal }) =>
    getSingleCompetitionFeed(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSingleCompetitionFeed>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSingleCompetitionFeedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSingleCompetitionFeed>>
>;
export type GetSingleCompetitionFeedQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Single Competition Feed
 */

export function useGetSingleCompetitionFeed<
  TData = Awaited<ReturnType<typeof getSingleCompetitionFeed>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: GetSingleCompetitionFeedParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSingleCompetitionFeed>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSingleCompetitionFeedQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Clone Competition
 */
export const cloneCompetition = (
  tenantUuid: string,
  competitionUuid: string,
  competitionCloneSchema: CompetitionCloneSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/actions/clone`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: competitionCloneSchema,
      signal,
    },
    options
  );
};

export const getCloneCompetitionMutationOptions = <
  TData = Awaited<ReturnType<typeof cloneCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionCloneSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["cloneCompetition"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cloneCompetition>>,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionCloneSchema;
    }
  > = (props) => {
    const { tenantUuid, competitionUuid, data } = props ?? {};

    return cloneCompetition(tenantUuid, competitionUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionCloneSchema;
    },
    TContext
  >;
};

export type CloneCompetitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof cloneCompetition>>
>;
export type CloneCompetitionMutationBody = CompetitionCloneSchema;
export type CloneCompetitionMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Clone Competition
 */
export const useCloneCompetition = <
  TData = Awaited<ReturnType<typeof cloneCompetition>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      competitionUuid: string;
      data: CompetitionCloneSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; competitionUuid: string; data: CompetitionCloneSchema },
  TContext
> => {
  const mutationOptions = getCloneCompetitionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Evaluation Reports
 */
export const getEvaluationReports = (
  tenantUuid: string,
  competitionUuid: string,
  params?: GetEvaluationReportsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<EvaluationReportSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/evaluation-reports`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetEvaluationReportsQueryKey = (
  tenantUuid: string,
  competitionUuid: string,
  params?: GetEvaluationReportsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competitions/${competitionUuid}/evaluation-reports`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetEvaluationReportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getEvaluationReports>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: GetEvaluationReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEvaluationReports>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetEvaluationReportsQueryKey(tenantUuid, competitionUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEvaluationReports>>
  > = ({ signal }) =>
    getEvaluationReports(
      tenantUuid,
      competitionUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEvaluationReports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEvaluationReportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEvaluationReports>>
>;
export type GetEvaluationReportsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Evaluation Reports
 */

export function useGetEvaluationReports<
  TData = Awaited<ReturnType<typeof getEvaluationReports>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  params?: GetEvaluationReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEvaluationReports>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetEvaluationReportsQueryOptions(
    tenantUuid,
    competitionUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
