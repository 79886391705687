/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type ConversationContactType =
  (typeof ConversationContactType)[keyof typeof ConversationContactType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConversationContactType = {
  service_titan_customer: "service_titan_customer",
  demo_customer: "demo_customer",
  new_customer: "new_customer",
} as const;
