/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Deep Health Check
 */
export const deepHealthCheck = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    { url: `/api/v1/deep-health`, method: "GET", signal },
    options
  );
};

export const getDeepHealthCheckQueryKey = () => {
  return [`/api/v1/deep-health`] as const;
};

export const getDeepHealthCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof deepHealthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof deepHealthCheck>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDeepHealthCheckQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof deepHealthCheck>>> = ({
    signal,
  }) => deepHealthCheck(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof deepHealthCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DeepHealthCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof deepHealthCheck>>
>;
export type DeepHealthCheckQueryError = ErrorType<unknown>;

/**
 * @summary Deep Health Check
 */

export function useDeepHealthCheck<
  TData = Awaited<ReturnType<typeof deepHealthCheck>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof deepHealthCheck>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDeepHealthCheckQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
