/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AudienceLeadsSchema,
  BoostSchemasLeadGenCampaignSchemaCampaignSchema,
  CampaignStatsSchema,
  CampaignsSearchResultSchema,
  ConversationListSchema,
  CreateCampaignSchemaInput,
  CreateCampaignSchemaOutput,
  GetCampaignsParams,
  HTTPValidationError,
  SearchCampaignConversationsParams,
  SendCampaignMessageDataSchema,
  SendCampaignMessageResponseSchema,
  SendTestCampaignMessageDataSchema,
  SendTestCampaignMessageResponseSchema,
  UpdateCampaignSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Campaigns
 */
export const getCampaigns = (
  tenantUuid: string,
  params?: GetCampaignsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CampaignsSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCampaignsQueryKey = (
  tenantUuid: string,
  params?: GetCampaignsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCampaignsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCampaigns>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCampaignsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaigns>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCampaignsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCampaigns>>> = ({
    signal,
  }) => getCampaigns(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCampaigns>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCampaignsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCampaigns>>
>;
export type GetCampaignsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Campaigns
 */

export function useGetCampaigns<
  TData = Awaited<ReturnType<typeof getCampaigns>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCampaignsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaigns>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCampaignsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Campaign
 */
export const getCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSchemasLeadGenCampaignSchemaCampaignSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCampaignQueryKey = (
  tenantUuid: string,
  campaignUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
  ] as const;
};

export const getGetCampaignQueryOptions = <
  TData = Awaited<ReturnType<typeof getCampaign>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaign>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCampaignQueryKey(tenantUuid, campaignUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCampaign>>> = ({
    signal,
  }) => getCampaign(tenantUuid, campaignUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && campaignUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCampaign>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCampaignQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCampaign>>
>;
export type GetCampaignQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Campaign
 */

export function useGetCampaign<
  TData = Awaited<ReturnType<typeof getCampaign>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaign>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCampaignQueryOptions(
    tenantUuid,
    campaignUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Campaign
 */
export const updateCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  updateCampaignSchema: UpdateCampaignSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostSchemasLeadGenCampaignSchemaCampaignSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: updateCampaignSchema,
    },
    options
  );
};

export const getUpdateCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string; data: UpdateCampaignSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCampaign>>,
    { tenantUuid: string; campaignUuid: string; data: UpdateCampaignSchema }
  > = (props) => {
    const { tenantUuid, campaignUuid, data } = props ?? {};

    return updateCampaign(tenantUuid, campaignUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string; data: UpdateCampaignSchema },
    TContext
  >;
};

export type UpdateCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCampaign>>
>;
export type UpdateCampaignMutationBody = UpdateCampaignSchema;
export type UpdateCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Campaign
 */
export const useUpdateCampaign = <
  TData = Awaited<ReturnType<typeof updateCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string; data: UpdateCampaignSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; campaignUuid: string; data: UpdateCampaignSchema },
  TContext
> => {
  const mutationOptions = getUpdateCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Campaign
 */
export const deleteCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["deleteCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCampaign>>,
    { tenantUuid: string; campaignUuid: string }
  > = (props) => {
    const { tenantUuid, campaignUuid } = props ?? {};

    return deleteCampaign(tenantUuid, campaignUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
};

export type DeleteCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCampaign>>
>;

export type DeleteCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Campaign
 */
export const useDeleteCampaign = <
  TData = Awaited<ReturnType<typeof deleteCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; campaignUuid: string },
  TContext
> => {
  const mutationOptions = getDeleteCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Campaign Audience
 */
export const getCampaignAudience = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceLeadsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/audience`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCampaignAudienceQueryKey = (
  tenantUuid: string,
  campaignUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/audience`,
  ] as const;
};

export const getGetCampaignAudienceQueryOptions = <
  TData = Awaited<ReturnType<typeof getCampaignAudience>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaignAudience>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCampaignAudienceQueryKey(tenantUuid, campaignUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCampaignAudience>>
  > = ({ signal }) =>
    getCampaignAudience(tenantUuid, campaignUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && campaignUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCampaignAudience>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCampaignAudienceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCampaignAudience>>
>;
export type GetCampaignAudienceQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Campaign Audience
 */

export function useGetCampaignAudience<
  TData = Awaited<ReturnType<typeof getCampaignAudience>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaignAudience>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCampaignAudienceQueryOptions(
    tenantUuid,
    campaignUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Campaign
 */
export const createCampaign = (
  tenantUuid: string,
  createCampaignSchemaInput: CreateCampaignSchemaInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSchemasLeadGenCampaignSchemaCampaignSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCampaignSchemaInput,
      signal,
    },
    options
  );
};

export const getCreateCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof createCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCampaign>>,
    { tenantUuid: string; data: CreateCampaignSchemaInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createCampaign(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
};

export type CreateCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCampaign>>
>;
export type CreateCampaignMutationBody = CreateCampaignSchemaInput;
export type CreateCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Campaign
 */
export const useCreateCampaign = <
  TData = Awaited<ReturnType<typeof createCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CreateCampaignSchemaInput },
  TContext
> => {
  const mutationOptions = getCreateCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Generate Campaign Title
 */
export const generateCampaignTitle = (
  tenantUuid: string,
  createCampaignSchemaInput: CreateCampaignSchemaInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CreateCampaignSchemaOutput>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/generate/title`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCampaignSchemaInput,
      signal,
    },
    options
  );
};

export const getGenerateCampaignTitleMutationOptions = <
  TData = Awaited<ReturnType<typeof generateCampaignTitle>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["generateCampaignTitle"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateCampaignTitle>>,
    { tenantUuid: string; data: CreateCampaignSchemaInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return generateCampaignTitle(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
};

export type GenerateCampaignTitleMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateCampaignTitle>>
>;
export type GenerateCampaignTitleMutationBody = CreateCampaignSchemaInput;
export type GenerateCampaignTitleMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Campaign Title
 */
export const useGenerateCampaignTitle = <
  TData = Awaited<ReturnType<typeof generateCampaignTitle>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CreateCampaignSchemaInput },
  TContext
> => {
  const mutationOptions = getGenerateCampaignTitleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Generate Campaign Description
 */
export const generateCampaignDescription = (
  tenantUuid: string,
  createCampaignSchemaInput: CreateCampaignSchemaInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CreateCampaignSchemaOutput>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/generate/description`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCampaignSchemaInput,
      signal,
    },
    options
  );
};

export const getGenerateCampaignDescriptionMutationOptions = <
  TData = Awaited<ReturnType<typeof generateCampaignDescription>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["generateCampaignDescription"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateCampaignDescription>>,
    { tenantUuid: string; data: CreateCampaignSchemaInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return generateCampaignDescription(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
};

export type GenerateCampaignDescriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateCampaignDescription>>
>;
export type GenerateCampaignDescriptionMutationBody = CreateCampaignSchemaInput;
export type GenerateCampaignDescriptionMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Generate Campaign Description
 */
export const useGenerateCampaignDescription = <
  TData = Awaited<ReturnType<typeof generateCampaignDescription>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateCampaignSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CreateCampaignSchemaInput },
  TContext
> => {
  const mutationOptions =
    getGenerateCampaignDescriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Start Campaign
 */
export const startCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/start`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getStartCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof startCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["startCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startCampaign>>,
    { tenantUuid: string; campaignUuid: string }
  > = (props) => {
    const { tenantUuid, campaignUuid } = props ?? {};

    return startCampaign(tenantUuid, campaignUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
};

export type StartCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof startCampaign>>
>;

export type StartCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Start Campaign
 */
export const useStartCampaign = <
  TData = Awaited<ReturnType<typeof startCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; campaignUuid: string },
  TContext
> => {
  const mutationOptions = getStartCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Pause Campaign
 */
export const pauseCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/pause`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getPauseCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof pauseCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["pauseCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pauseCampaign>>,
    { tenantUuid: string; campaignUuid: string }
  > = (props) => {
    const { tenantUuid, campaignUuid } = props ?? {};

    return pauseCampaign(tenantUuid, campaignUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
};

export type PauseCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof pauseCampaign>>
>;

export type PauseCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Pause Campaign
 */
export const usePauseCampaign = <
  TData = Awaited<ReturnType<typeof pauseCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; campaignUuid: string },
  TContext
> => {
  const mutationOptions = getPauseCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive Campaign
 */
export const archiveCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/archive`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getArchiveCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof archiveCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["archiveCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof archiveCampaign>>,
    { tenantUuid: string; campaignUuid: string }
  > = (props) => {
    const { tenantUuid, campaignUuid } = props ?? {};

    return archiveCampaign(tenantUuid, campaignUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
};

export type ArchiveCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof archiveCampaign>>
>;

export type ArchiveCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Archive Campaign
 */
export const useArchiveCampaign = <
  TData = Awaited<ReturnType<typeof archiveCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; campaignUuid: string },
  TContext
> => {
  const mutationOptions = getArchiveCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Test Message
 */
export const sendTestMessage = (
  tenantUuid: string,
  campaignUuid: string,
  sendTestCampaignMessageDataSchema: SendTestCampaignMessageDataSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<SendTestCampaignMessageResponseSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/send-test-message`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendTestCampaignMessageDataSchema,
      signal,
    },
    options
  );
};

export const getSendTestMessageMutationOptions = <
  TData = Awaited<ReturnType<typeof sendTestMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendTestCampaignMessageDataSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendTestMessage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendTestMessage>>,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendTestCampaignMessageDataSchema;
    }
  > = (props) => {
    const { tenantUuid, campaignUuid, data } = props ?? {};

    return sendTestMessage(tenantUuid, campaignUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendTestCampaignMessageDataSchema;
    },
    TContext
  >;
};

export type SendTestMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendTestMessage>>
>;
export type SendTestMessageMutationBody = SendTestCampaignMessageDataSchema;
export type SendTestMessageMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Test Message
 */
export const useSendTestMessage = <
  TData = Awaited<ReturnType<typeof sendTestMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendTestCampaignMessageDataSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    campaignUuid: string;
    data: SendTestCampaignMessageDataSchema;
  },
  TContext
> => {
  const mutationOptions = getSendTestMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Message
 */
export const sendMessage = (
  tenantUuid: string,
  campaignUuid: string,
  sendCampaignMessageDataSchema: SendCampaignMessageDataSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<SendCampaignMessageResponseSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/send-message`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendCampaignMessageDataSchema,
      signal,
    },
    options
  );
};

export const getSendMessageMutationOptions = <
  TData = Awaited<ReturnType<typeof sendMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendCampaignMessageDataSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendMessage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMessage>>,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendCampaignMessageDataSchema;
    }
  > = (props) => {
    const { tenantUuid, campaignUuid, data } = props ?? {};

    return sendMessage(tenantUuid, campaignUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendCampaignMessageDataSchema;
    },
    TContext
  >;
};

export type SendMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendMessage>>
>;
export type SendMessageMutationBody = SendCampaignMessageDataSchema;
export type SendMessageMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Message
 */
export const useSendMessage = <
  TData = Awaited<ReturnType<typeof sendMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      campaignUuid: string;
      data: SendCampaignMessageDataSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    campaignUuid: string;
    data: SendCampaignMessageDataSchema;
  },
  TContext
> => {
  const mutationOptions = getSendMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Campaign Conversations
 */
export const searchCampaignConversations = (
  tenantUuid: string,
  campaignUuid: string,
  params?: SearchCampaignConversationsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ConversationListSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/conversations/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchCampaignConversationsQueryKey = (
  tenantUuid: string,
  campaignUuid: string,
  params?: SearchCampaignConversationsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/conversations/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchCampaignConversationsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchCampaignConversations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  params?: SearchCampaignConversationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCampaignConversations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchCampaignConversationsQueryKey(tenantUuid, campaignUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchCampaignConversations>>
  > = ({ signal }) =>
    searchCampaignConversations(
      tenantUuid,
      campaignUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && campaignUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchCampaignConversations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchCampaignConversationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchCampaignConversations>>
>;
export type SearchCampaignConversationsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search Campaign Conversations
 */

export function useSearchCampaignConversations<
  TData = Awaited<ReturnType<typeof searchCampaignConversations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  params?: SearchCampaignConversationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCampaignConversations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchCampaignConversationsQueryOptions(
    tenantUuid,
    campaignUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Campaign Stats
 */
export const getCampaignStats = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CampaignStatsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/stats`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCampaignStatsQueryKey = (
  tenantUuid: string,
  campaignUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}/stats`,
  ] as const;
};

export const getGetCampaignStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCampaignStats>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaignStats>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCampaignStatsQueryKey(tenantUuid, campaignUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCampaignStats>>
  > = ({ signal }) =>
    getCampaignStats(tenantUuid, campaignUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && campaignUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCampaignStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCampaignStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCampaignStats>>
>;
export type GetCampaignStatsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Campaign Stats
 */

export function useGetCampaignStats<
  TData = Awaited<ReturnType<typeof getCampaignStats>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaignStats>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCampaignStatsQueryOptions(
    tenantUuid,
    campaignUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
