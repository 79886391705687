/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type CampaignStatus =
  (typeof CampaignStatus)[keyof typeof CampaignStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CampaignStatus = {
  created: "created",
  active: "active",
  archived: "archived",
} as const;
