/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type OutcomeType = (typeof OutcomeType)[keyof typeof OutcomeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutcomeType = {
  membership: "membership",
  review: "review",
  mindshare: "mindshare",
  upsell: "upsell",
  job: "job",
} as const;
