/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BusinessUnit,
  GetBusinessUnitsParams,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Business Units
 */
export const getBusinessUnits = (
  tenantUuid: string,
  params?: GetBusinessUnitsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BusinessUnit[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/business-units`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetBusinessUnitsQueryKey = (
  tenantUuid: string,
  params?: GetBusinessUnitsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/business-units`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetBusinessUnitsQueryOptions = <
  TData = Awaited<ReturnType<typeof getBusinessUnits>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetBusinessUnitsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBusinessUnits>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBusinessUnitsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBusinessUnits>>
  > = ({ signal }) =>
    getBusinessUnits(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBusinessUnits>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBusinessUnitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBusinessUnits>>
>;
export type GetBusinessUnitsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Business Units
 */

export function useGetBusinessUnits<
  TData = Awaited<ReturnType<typeof getBusinessUnits>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetBusinessUnitsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBusinessUnits>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetBusinessUnitsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Business Unit
 */
export const getBusinessUnit = (
  tenantUuid: string,
  businessUnitId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BusinessUnit>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/business-units/${businessUnitId}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetBusinessUnitQueryKey = (
  tenantUuid: string,
  businessUnitId: number
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/business-units/${businessUnitId}`,
  ] as const;
};

export const getGetBusinessUnitQueryOptions = <
  TData = Awaited<ReturnType<typeof getBusinessUnit>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  businessUnitId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBusinessUnit>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBusinessUnitQueryKey(tenantUuid, businessUnitId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessUnit>>> = ({
    signal,
  }) => getBusinessUnit(tenantUuid, businessUnitId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && businessUnitId),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBusinessUnit>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBusinessUnitQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBusinessUnit>>
>;
export type GetBusinessUnitQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Business Unit
 */

export function useGetBusinessUnit<
  TData = Awaited<ReturnType<typeof getBusinessUnit>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  businessUnitId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBusinessUnit>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetBusinessUnitQueryOptions(
    tenantUuid,
    businessUnitId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
