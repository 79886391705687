/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateIntegrationResultSchema,
  HTTPValidationError,
  TenantCreateSchema,
  TenantIntegrationCreateSchema,
  TenantIntegrationSchema,
  TenantOrganization,
  TenantOrganizationCreate,
  TenantSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Tenants Admin
 */
export const getTenantsAdmin = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantSchema[]>(
    { url: `/api/v1/admin/tenants`, method: "GET", signal },
    options
  );
};

export const getGetTenantsAdminQueryKey = () => {
  return [`/api/v1/admin/tenants`] as const;
};

export const getGetTenantsAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getTenantsAdmin>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTenantsAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTenantsAdminQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantsAdmin>>> = ({
    signal,
  }) => getTenantsAdmin(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTenantsAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTenantsAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTenantsAdmin>>
>;
export type GetTenantsAdminQueryError = ErrorType<unknown>;

/**
 * @summary Get Tenants Admin
 */

export function useGetTenantsAdmin<
  TData = Awaited<ReturnType<typeof getTenantsAdmin>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTenantsAdmin>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTenantsAdminQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Tenant Admin
 */
export const createTenantAdmin = (
  tenantCreateSchema: TenantCreateSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantSchema>(
    {
      url: `/api/v1/admin/tenants`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: tenantCreateSchema,
      signal,
    },
    options
  );
};

export const getCreateTenantAdminMutationOptions = <
  TData = Awaited<ReturnType<typeof createTenantAdmin>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: TenantCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createTenantAdmin"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTenantAdmin>>,
    { data: TenantCreateSchema }
  > = (props) => {
    const { data } = props ?? {};

    return createTenantAdmin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: TenantCreateSchema },
    TContext
  >;
};

export type CreateTenantAdminMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTenantAdmin>>
>;
export type CreateTenantAdminMutationBody = TenantCreateSchema;
export type CreateTenantAdminMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Tenant Admin
 */
export const useCreateTenantAdmin = <
  TData = Awaited<ReturnType<typeof createTenantAdmin>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: TenantCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: TenantCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateTenantAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Integrations Admin
 */
export const getIntegrationsAdmin = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantIntegrationSchema[]>(
    { url: `/api/v1/admin/${tenantUuid}/integrations`, method: "GET", signal },
    options
  );
};

export const getGetIntegrationsAdminQueryKey = (tenantUuid: string) => {
  return [`/api/v1/admin/${tenantUuid}/integrations`] as const;
};

export const getGetIntegrationsAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getIntegrationsAdmin>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIntegrationsAdmin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIntegrationsAdminQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIntegrationsAdmin>>
  > = ({ signal }) => getIntegrationsAdmin(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIntegrationsAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIntegrationsAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntegrationsAdmin>>
>;
export type GetIntegrationsAdminQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Integrations Admin
 */

export function useGetIntegrationsAdmin<
  TData = Awaited<ReturnType<typeof getIntegrationsAdmin>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIntegrationsAdmin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIntegrationsAdminQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Integration Admin
 */
export const createIntegrationAdmin = (
  tenantUuid: string,
  tenantIntegrationCreateSchema: TenantIntegrationCreateSchema[],
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CreateIntegrationResultSchema[]>(
    {
      url: `/api/v1/admin/${tenantUuid}/integrations`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: tenantIntegrationCreateSchema,
      signal,
    },
    options
  );
};

export const getCreateIntegrationAdminMutationOptions = <
  TData = Awaited<ReturnType<typeof createIntegrationAdmin>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantIntegrationCreateSchema[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createIntegrationAdmin"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createIntegrationAdmin>>,
    { tenantUuid: string; data: TenantIntegrationCreateSchema[] }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createIntegrationAdmin(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantIntegrationCreateSchema[] },
    TContext
  >;
};

export type CreateIntegrationAdminMutationResult = NonNullable<
  Awaited<ReturnType<typeof createIntegrationAdmin>>
>;
export type CreateIntegrationAdminMutationBody =
  TenantIntegrationCreateSchema[];
export type CreateIntegrationAdminMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Integration Admin
 */
export const useCreateIntegrationAdmin = <
  TData = Awaited<ReturnType<typeof createIntegrationAdmin>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantIntegrationCreateSchema[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: TenantIntegrationCreateSchema[] },
  TContext
> => {
  const mutationOptions = getCreateIntegrationAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Organizations Admin
 */
export const getOrganizationsAdmin = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantOrganization[]>(
    {
      url: `/api/v1/admin/${tenantUuid}/organizations/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetOrganizationsAdminQueryKey = (tenantUuid: string) => {
  return [`/api/v1/admin/${tenantUuid}/organizations/`] as const;
};

export const getGetOrganizationsAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationsAdmin>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrganizationsAdmin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOrganizationsAdminQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrganizationsAdmin>>
  > = ({ signal }) => getOrganizationsAdmin(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationsAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrganizationsAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganizationsAdmin>>
>;
export type GetOrganizationsAdminQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Organizations Admin
 */

export function useGetOrganizationsAdmin<
  TData = Awaited<ReturnType<typeof getOrganizationsAdmin>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrganizationsAdmin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrganizationsAdminQueryOptions(
    tenantUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Organizations Admin
 */
export const createOrganizationsAdmin = (
  tenantUuid: string,
  tenantOrganizationCreate: TenantOrganizationCreate[],
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantOrganization[]>(
    {
      url: `/api/v1/admin/${tenantUuid}/organizations/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: tenantOrganizationCreate,
      signal,
    },
    options
  );
};

export const getCreateOrganizationsAdminMutationOptions = <
  TData = Awaited<ReturnType<typeof createOrganizationsAdmin>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantOrganizationCreate[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createOrganizationsAdmin"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrganizationsAdmin>>,
    { tenantUuid: string; data: TenantOrganizationCreate[] }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createOrganizationsAdmin(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantOrganizationCreate[] },
    TContext
  >;
};

export type CreateOrganizationsAdminMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrganizationsAdmin>>
>;
export type CreateOrganizationsAdminMutationBody = TenantOrganizationCreate[];
export type CreateOrganizationsAdminMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Organizations Admin
 */
export const useCreateOrganizationsAdmin = <
  TData = Awaited<ReturnType<typeof createOrganizationsAdmin>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantOrganizationCreate[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: TenantOrganizationCreate[] },
  TContext
> => {
  const mutationOptions = getCreateOrganizationsAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Organization Admin
 */
export const getOrganizationAdmin = (
  tenantUuid: string,
  organizationUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantOrganization>(
    {
      url: `/api/v1/admin/${tenantUuid}/organizations/${organizationUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetOrganizationAdminQueryKey = (
  tenantUuid: string,
  organizationUuid: string
) => {
  return [
    `/api/v1/admin/${tenantUuid}/organizations/${organizationUuid}`,
  ] as const;
};

export const getGetOrganizationAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizationAdmin>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  organizationUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrganizationAdmin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrganizationAdminQueryKey(tenantUuid, organizationUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrganizationAdmin>>
  > = ({ signal }) =>
    getOrganizationAdmin(tenantUuid, organizationUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && organizationUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganizationAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrganizationAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganizationAdmin>>
>;
export type GetOrganizationAdminQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Organization Admin
 */

export function useGetOrganizationAdmin<
  TData = Awaited<ReturnType<typeof getOrganizationAdmin>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  organizationUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrganizationAdmin>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrganizationAdminQueryOptions(
    tenantUuid,
    organizationUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
