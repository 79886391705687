/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  GetAvailablePhoneNumbersParams,
  HTTPValidationError,
  PhoneNumberListResponse,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Available Phone Numbers
 */
export const getAvailablePhoneNumbers = (
  tenantUuid: string,
  params?: GetAvailablePhoneNumbersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PhoneNumberListResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/numbers`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetAvailablePhoneNumbersQueryKey = (
  tenantUuid: string,
  params?: GetAvailablePhoneNumbersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/numbers`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAvailablePhoneNumbersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailablePhoneNumbers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetAvailablePhoneNumbersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvailablePhoneNumbers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAvailablePhoneNumbersQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAvailablePhoneNumbers>>
  > = ({ signal }) =>
    getAvailablePhoneNumbers(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAvailablePhoneNumbers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAvailablePhoneNumbersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailablePhoneNumbers>>
>;
export type GetAvailablePhoneNumbersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Available Phone Numbers
 */

export function useGetAvailablePhoneNumbers<
  TData = Awaited<ReturnType<typeof getAvailablePhoneNumbers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetAvailablePhoneNumbersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAvailablePhoneNumbers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAvailablePhoneNumbersQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
