/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostSearchBusinessUnitResultSchema,
  BoostSearchCampaignResultSchema,
  BoostSearchERPUserResultSchema,
  BoostSearchMembershipTypeResultSchema,
  BoostSearchSkuResultSchema,
  BoostSearchTagResultSchema,
  HTTPValidationError,
  SearchBusinessUnitsParams,
  SearchCampaignsParams,
  SearchErpUsersParams,
  SearchMembershipTypesParams,
  SearchSkusParams,
  SearchTagsParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Skus
 */
export const searchSkus = (
  tenantUuid: string,
  params: SearchSkusParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchSkuResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/skus/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchSkusQueryKey = (
  tenantUuid: string,
  params: SearchSkusParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/skus/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchSkusQueryOptions = <
  TData = Awaited<ReturnType<typeof searchSkus>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchSkusParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchSkus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchSkusQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchSkus>>> = ({
    signal,
  }) => searchSkus(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchSkus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchSkusQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchSkus>>
>;
export type SearchSkusQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Skus
 */

export function useSearchSkus<
  TData = Awaited<ReturnType<typeof searchSkus>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchSkusParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchSkus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchSkusQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search Membership Types
 */
export const searchMembershipTypes = (
  tenantUuid: string,
  params: SearchMembershipTypesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchMembershipTypeResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/membership-types/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchMembershipTypesQueryKey = (
  tenantUuid: string,
  params: SearchMembershipTypesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/membership-types/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchMembershipTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchMembershipTypes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchMembershipTypesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchMembershipTypes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchMembershipTypesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchMembershipTypes>>
  > = ({ signal }) =>
    searchMembershipTypes(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchMembershipTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchMembershipTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchMembershipTypes>>
>;
export type SearchMembershipTypesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Membership Types
 */

export function useSearchMembershipTypes<
  TData = Awaited<ReturnType<typeof searchMembershipTypes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchMembershipTypesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchMembershipTypes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchMembershipTypesQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search Business Units
 */
export const searchBusinessUnits = (
  tenantUuid: string,
  params: SearchBusinessUnitsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchBusinessUnitResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/business-units/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchBusinessUnitsQueryKey = (
  tenantUuid: string,
  params: SearchBusinessUnitsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/business-units/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchBusinessUnitsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchBusinessUnits>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchBusinessUnitsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchBusinessUnits>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchBusinessUnitsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchBusinessUnits>>
  > = ({ signal }) =>
    searchBusinessUnits(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchBusinessUnits>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchBusinessUnitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchBusinessUnits>>
>;
export type SearchBusinessUnitsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Business Units
 */

export function useSearchBusinessUnits<
  TData = Awaited<ReturnType<typeof searchBusinessUnits>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchBusinessUnitsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchBusinessUnits>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchBusinessUnitsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search Campaigns
 */
export const searchCampaigns = (
  tenantUuid: string,
  params: SearchCampaignsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchCampaignResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/campaigns/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchCampaignsQueryKey = (
  tenantUuid: string,
  params: SearchCampaignsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/campaigns/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchCampaignsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchCampaigns>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchCampaignsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCampaigns>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchCampaignsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchCampaigns>>> = ({
    signal,
  }) => searchCampaigns(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchCampaigns>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchCampaignsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchCampaigns>>
>;
export type SearchCampaignsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Campaigns
 */

export function useSearchCampaigns<
  TData = Awaited<ReturnType<typeof searchCampaigns>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchCampaignsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCampaigns>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchCampaignsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Search for ERP users with optional filtering by search term.

This endpoint allows searching for ERP users by name or email. Results are paginated.

Args:
    input_data: Search parameters including integration_uuid, search_term, and pagination
    
Returns:
    A list of ERP users matching the search criteria with pagination metadata
 * @summary Search Erp Users
 */
export const searchErpUsers = (
  tenantUuid: string,
  params: SearchErpUsersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchERPUserResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/users/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchErpUsersQueryKey = (
  tenantUuid: string,
  params: SearchErpUsersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/users/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchErpUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchErpUsers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchErpUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchErpUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchErpUsersQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchErpUsers>>> = ({
    signal,
  }) => searchErpUsers(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchErpUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchErpUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchErpUsers>>
>;
export type SearchErpUsersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Erp Users
 */

export function useSearchErpUsers<
  TData = Awaited<ReturnType<typeof searchErpUsers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchErpUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchErpUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchErpUsersQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search Tags
 */
export const searchTags = (
  tenantUuid: string,
  params: SearchTagsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchTagResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/tags/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchTagsQueryKey = (
  tenantUuid: string,
  params: SearchTagsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/tags/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchTagsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchTags>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchTagsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTags>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchTagsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchTags>>> = ({
    signal,
  }) => searchTags(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchTagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchTags>>
>;
export type SearchTagsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Tags
 */

export function useSearchTags<
  TData = Awaited<ReturnType<typeof searchTags>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchTagsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTags>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchTagsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
