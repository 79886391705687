/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  EventReplaySchema,
  GetEventsParams,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Replay Events
 */
export const replayEvents = (
  tenantUuid: string,
  eventReplaySchema: EventReplaySchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/event-replay`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: eventReplaySchema,
      signal,
    },
    options
  );
};

export const getReplayEventsMutationOptions = <
  TData = Awaited<ReturnType<typeof replayEvents>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: EventReplaySchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["replayEvents"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof replayEvents>>,
    { tenantUuid: string; data: EventReplaySchema }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return replayEvents(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: EventReplaySchema },
    TContext
  >;
};

export type ReplayEventsMutationResult = NonNullable<
  Awaited<ReturnType<typeof replayEvents>>
>;
export type ReplayEventsMutationBody = EventReplaySchema;
export type ReplayEventsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Replay Events
 */
export const useReplayEvents = <
  TData = Awaited<ReturnType<typeof replayEvents>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: EventReplaySchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: EventReplaySchema },
  TContext
> => {
  const mutationOptions = getReplayEventsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Events
 */
export const getEvents = (
  tenantUuid: string,
  params: GetEventsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/events`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetEventsQueryKey = (
  tenantUuid: string,
  params: GetEventsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/events`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof getEvents>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEvents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEventsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvents>>> = ({
    signal,
  }) => getEvents(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getEvents>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEvents>>
>;
export type GetEventsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Events
 */

export function useGetEvents<
  TData = Awaited<ReturnType<typeof getEvents>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEvents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetEventsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Event Queue Length
 */
export const getEventQueueLength = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<number>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/event-queue-length`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetEventQueueLengthQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/event-queue-length`] as const;
};

export const getGetEventQueueLengthQueryOptions = <
  TData = Awaited<ReturnType<typeof getEventQueueLength>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEventQueueLength>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEventQueueLengthQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEventQueueLength>>
  > = ({ signal }) => getEventQueueLength(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEventQueueLength>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEventQueueLengthQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEventQueueLength>>
>;
export type GetEventQueueLengthQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Event Queue Length
 */

export function useGetEventQueueLength<
  TData = Awaited<ReturnType<typeof getEventQueueLength>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEventQueueLength>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetEventQueueLengthQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
