/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  GetOutcomesParams,
  HTTPValidationError,
  OutcomeSchema,
  OutcomesSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Outcomes
 */
export const getOutcomes = (
  tenantUuid: string,
  params?: GetOutcomesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<OutcomesSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/outcomes`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetOutcomesQueryKey = (
  tenantUuid: string,
  params?: GetOutcomesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/outcomes`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetOutcomesQueryOptions = <
  TData = Awaited<ReturnType<typeof getOutcomes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetOutcomesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOutcomes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOutcomesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOutcomes>>> = ({
    signal,
  }) => getOutcomes(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOutcomes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOutcomesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOutcomes>>
>;
export type GetOutcomesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Outcomes
 */

export function useGetOutcomes<
  TData = Awaited<ReturnType<typeof getOutcomes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetOutcomesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOutcomes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOutcomesQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Outcome
 */
export const getOutcome = (
  tenantUuid: string,
  outcomeUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<OutcomeSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/outcomes/${outcomeUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetOutcomeQueryKey = (
  tenantUuid: string,
  outcomeUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/outcomes/${outcomeUuid}`,
  ] as const;
};

export const getGetOutcomeQueryOptions = <
  TData = Awaited<ReturnType<typeof getOutcome>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  outcomeUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOutcome>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOutcomeQueryKey(tenantUuid, outcomeUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOutcome>>> = ({
    signal,
  }) => getOutcome(tenantUuid, outcomeUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && outcomeUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOutcome>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOutcomeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOutcome>>
>;
export type GetOutcomeQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Outcome
 */

export function useGetOutcome<
  TData = Awaited<ReturnType<typeof getOutcome>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  outcomeUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOutcome>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOutcomeQueryOptions(
    tenantUuid,
    outcomeUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
