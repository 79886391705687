/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type BoostEventType =
  (typeof BoostEventType)[keyof typeof BoostEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostEventType = {
  job_updated: "job_updated",
  estimate_updated: "estimate_updated",
  invoice_updated: "invoice_updated",
  lead_call_updated: "lead_call_updated",
  review_updated: "review_updated",
  user_updated: "user_updated",
  user_created: "user_created",
  user_deactivated: "user_deactivated",
  membership_updated: "membership_updated",
  campaign_started: "campaign_started",
  campaign_paused: "campaign_paused",
  campaign_archived: "campaign_archived",
  message_create: "message_create",
  test_message_create: "test_message_create",
} as const;
