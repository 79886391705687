/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostCreateUserSchema,
  BoostSearchUserResultSchema,
  BoostUserSchema,
  ChangeUserRoleInputSchema,
  ErpUserLinkData,
  GetRoleNamesParams,
  GetTeamNamesParams,
  HTTPValidationError,
  OnboardUserBody,
  ResendWelcomeMessageBody,
  SearchUsersParams,
  TechnicianRevenueResponse,
  TenantUserProfileSchema,
  TenantUserProfileUpdateSchema,
  TenantUserUpdateSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Current Tenant User
 */
export const getCurrentTenantUser = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    { url: `/api/v1/tenants/${tenantUuid}/users/me`, method: "GET", signal },
    options
  );
};

export const getGetCurrentTenantUserQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/users/me`] as const;
};

export const getGetCurrentTenantUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentTenantUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentTenantUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCurrentTenantUserQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCurrentTenantUser>>
  > = ({ signal }) => getCurrentTenantUser(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentTenantUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentTenantUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentTenantUser>>
>;
export type GetCurrentTenantUserQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Current Tenant User
 */

export function useGetCurrentTenantUser<
  TData = Awaited<ReturnType<typeof getCurrentTenantUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentTenantUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCurrentTenantUserQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Current Tenant User
 */
export const updateCurrentTenantUser = (
  tenantUuid: string,
  tenantUserUpdateSchema: TenantUserUpdateSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/me`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: tenantUserUpdateSchema,
    },
    options
  );
};

export const getUpdateCurrentTenantUserMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCurrentTenantUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantUserUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateCurrentTenantUser"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCurrentTenantUser>>,
    { tenantUuid: string; data: TenantUserUpdateSchema }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return updateCurrentTenantUser(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantUserUpdateSchema },
    TContext
  >;
};

export type UpdateCurrentTenantUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCurrentTenantUser>>
>;
export type UpdateCurrentTenantUserMutationBody = TenantUserUpdateSchema;
export type UpdateCurrentTenantUserMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Update Current Tenant User
 */
export const useUpdateCurrentTenantUser = <
  TData = Awaited<ReturnType<typeof updateCurrentTenantUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: TenantUserUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: TenantUserUpdateSchema },
  TContext
> => {
  const mutationOptions = getUpdateCurrentTenantUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Users
 */
export const searchUsers = (
  tenantUuid: string,
  params?: SearchUsersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchUserResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchUsersQueryKey = (
  tenantUuid: string,
  params?: SearchUsersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/users/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchUsers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchUsersQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchUsers>>> = ({
    signal,
  }) => searchUsers(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchUsers>>
>;
export type SearchUsersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Users
 */

export function useSearchUsers<
  TData = Awaited<ReturnType<typeof searchUsers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchUsersQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Team Names
 */
export const getTeamNames = (
  tenantUuid: string,
  params: GetTeamNamesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/teams`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetTeamNamesQueryKey = (
  tenantUuid: string,
  params: GetTeamNamesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/users/teams`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetTeamNamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeamNames>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetTeamNamesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNames>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTeamNamesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamNames>>> = ({
    signal,
  }) => getTeamNames(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeamNames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamNamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamNames>>
>;
export type GetTeamNamesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Team Names
 */

export function useGetTeamNames<
  TData = Awaited<ReturnType<typeof getTeamNames>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetTeamNamesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNames>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTeamNamesQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Role Names
 */
export const getRoleNames = (
  tenantUuid: string,
  params: GetRoleNamesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/roles`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetRoleNamesQueryKey = (
  tenantUuid: string,
  params: GetRoleNamesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/users/roles`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetRoleNamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getRoleNames>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetRoleNamesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRoleNames>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetRoleNamesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoleNames>>> = ({
    signal,
  }) => getRoleNames(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRoleNames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRoleNamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRoleNames>>
>;
export type GetRoleNamesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Role Names
 */

export function useGetRoleNames<
  TData = Awaited<ReturnType<typeof getRoleNames>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: GetRoleNamesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRoleNames>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetRoleNamesQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create User
 */
export const createUser = (
  tenantUuid: string,
  boostCreateUserSchema: BoostCreateUserSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostCreateUserSchema,
      signal,
    },
    options
  );
};

export const getCreateUserMutationOptions = <
  TData = Awaited<ReturnType<typeof createUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostCreateUserSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createUser"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { tenantUuid: string; data: BoostCreateUserSchema }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createUser(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostCreateUserSchema },
    TContext
  >;
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = BoostCreateUserSchema;
export type CreateUserMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create User
 */
export const useCreateUser = <
  TData = Awaited<ReturnType<typeof createUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostCreateUserSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: BoostCreateUserSchema },
  TContext
> => {
  const mutationOptions = getCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Tenant User By Uuid
 */
export const getTenantUserByUuid = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTenantUserByUuidQueryKey = (
  tenantUuid: string,
  userUuid: string
) => {
  return [`/api/v1/tenants/${tenantUuid}/users/${userUuid}`] as const;
};

export const getGetTenantUserByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getTenantUserByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTenantUserByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTenantUserByUuidQueryKey(tenantUuid, userUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTenantUserByUuid>>
  > = ({ signal }) =>
    getTenantUserByUuid(tenantUuid, userUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && userUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTenantUserByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTenantUserByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTenantUserByUuid>>
>;
export type GetTenantUserByUuidQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Tenant User By Uuid
 */

export function useGetTenantUserByUuid<
  TData = Awaited<ReturnType<typeof getTenantUserByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTenantUserByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTenantUserByUuidQueryOptions(
    tenantUuid,
    userUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update User
 */
export const updateUser = (
  tenantUuid: string,
  userUuid: string,
  tenantUserUpdateSchema: TenantUserUpdateSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: tenantUserUpdateSchema,
    },
    options
  );
};

export const getUpdateUserMutationOptions = <
  TData = Awaited<ReturnType<typeof updateUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: TenantUserUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateUser"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { tenantUuid: string; userUuid: string; data: TenantUserUpdateSchema }
  > = (props) => {
    const { tenantUuid, userUuid, data } = props ?? {};

    return updateUser(tenantUuid, userUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: TenantUserUpdateSchema },
    TContext
  >;
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationBody = TenantUserUpdateSchema;
export type UpdateUserMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update User
 */
export const useUpdateUser = <
  TData = Awaited<ReturnType<typeof updateUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: TenantUserUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; userUuid: string; data: TenantUserUpdateSchema },
  TContext
> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deactivate User
 */
export const deactivateUser = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeactivateUserMutationOptions = <
  TData = Awaited<ReturnType<typeof deactivateUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["deactivateUser"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deactivateUser>>,
    { tenantUuid: string; userUuid: string }
  > = (props) => {
    const { tenantUuid, userUuid } = props ?? {};

    return deactivateUser(tenantUuid, userUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string },
    TContext
  >;
};

export type DeactivateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deactivateUser>>
>;

export type DeactivateUserMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Deactivate User
 */
export const useDeactivateUser = <
  TData = Awaited<ReturnType<typeof deactivateUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; userUuid: string },
  TContext
> => {
  const mutationOptions = getDeactivateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Current Tenant User Profile
 */
export const updateCurrentTenantUserProfile = (
  tenantUuid: string,
  userProfileUuid: string,
  tenantUserProfileUpdateSchema: TenantUserProfileUpdateSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TenantUserProfileSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/me/profile/${userProfileUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: tenantUserProfileUpdateSchema,
    },
    options
  );
};

export const getUpdateCurrentTenantUserProfileMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCurrentTenantUserProfile>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      userProfileUuid: string;
      data: TenantUserProfileUpdateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateCurrentTenantUserProfile"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCurrentTenantUserProfile>>,
    {
      tenantUuid: string;
      userProfileUuid: string;
      data: TenantUserProfileUpdateSchema;
    }
  > = (props) => {
    const { tenantUuid, userProfileUuid, data } = props ?? {};

    return updateCurrentTenantUserProfile(
      tenantUuid,
      userProfileUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      userProfileUuid: string;
      data: TenantUserProfileUpdateSchema;
    },
    TContext
  >;
};

export type UpdateCurrentTenantUserProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCurrentTenantUserProfile>>
>;
export type UpdateCurrentTenantUserProfileMutationBody =
  TenantUserProfileUpdateSchema;
export type UpdateCurrentTenantUserProfileMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Update Current Tenant User Profile
 */
export const useUpdateCurrentTenantUserProfile = <
  TData = Awaited<ReturnType<typeof updateCurrentTenantUserProfile>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      userProfileUuid: string;
      data: TenantUserProfileUpdateSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    userProfileUuid: string;
    data: TenantUserProfileUpdateSchema;
  },
  TContext
> => {
  const mutationOptions =
    getUpdateCurrentTenantUserProfileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get My User Revenue
 */
export const getMyUserRevenue = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TechnicianRevenueResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/me/revenue`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetMyUserRevenueQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/users/me/revenue`] as const;
};

export const getGetMyUserRevenueQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyUserRevenue>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMyUserRevenue>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMyUserRevenueQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMyUserRevenue>>
  > = ({ signal }) => getMyUserRevenue(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMyUserRevenue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyUserRevenueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMyUserRevenue>>
>;
export type GetMyUserRevenueQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get My User Revenue
 */

export function useGetMyUserRevenue<
  TData = Awaited<ReturnType<typeof getMyUserRevenue>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMyUserRevenue>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMyUserRevenueQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Onboard User
 */
export const onboardUser = (
  tenantUuid: string,
  userUuid: string,
  onboardUserBody: OnboardUserBody,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}/onboard`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: onboardUserBody,
      signal,
    },
    options
  );
};

export const getOnboardUserMutationOptions = <
  TData = Awaited<ReturnType<typeof onboardUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: OnboardUserBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["onboardUser"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof onboardUser>>,
    { tenantUuid: string; userUuid: string; data: OnboardUserBody }
  > = (props) => {
    const { tenantUuid, userUuid, data } = props ?? {};

    return onboardUser(tenantUuid, userUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: OnboardUserBody },
    TContext
  >;
};

export type OnboardUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof onboardUser>>
>;
export type OnboardUserMutationBody = OnboardUserBody;
export type OnboardUserMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Onboard User
 */
export const useOnboardUser = <
  TData = Awaited<ReturnType<typeof onboardUser>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: OnboardUserBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; userUuid: string; data: OnboardUserBody },
  TContext
> => {
  const mutationOptions = getOnboardUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Resend Welcome Message
 */
export const resendWelcomeMessage = (
  tenantUuid: string,
  userUuid: string,
  resendWelcomeMessageBody: ResendWelcomeMessageBody,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}/actions/resend-welcome-message`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: resendWelcomeMessageBody,
      signal,
    },
    options
  );
};

export const getResendWelcomeMessageMutationOptions = <
  TData = Awaited<ReturnType<typeof resendWelcomeMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: ResendWelcomeMessageBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["resendWelcomeMessage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resendWelcomeMessage>>,
    { tenantUuid: string; userUuid: string; data: ResendWelcomeMessageBody }
  > = (props) => {
    const { tenantUuid, userUuid, data } = props ?? {};

    return resendWelcomeMessage(tenantUuid, userUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: ResendWelcomeMessageBody },
    TContext
  >;
};

export type ResendWelcomeMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof resendWelcomeMessage>>
>;
export type ResendWelcomeMessageMutationBody = ResendWelcomeMessageBody;
export type ResendWelcomeMessageMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Resend Welcome Message
 */
export const useResendWelcomeMessage = <
  TData = Awaited<ReturnType<typeof resendWelcomeMessage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: ResendWelcomeMessageBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; userUuid: string; data: ResendWelcomeMessageBody },
  TContext
> => {
  const mutationOptions = getResendWelcomeMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Change User Role
 */
export const changeUserRole = (
  tenantUuid: string,
  userUuid: string,
  changeUserRoleInputSchema: ChangeUserRoleInputSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}/actions/change-role`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changeUserRoleInputSchema,
      signal,
    },
    options
  );
};

export const getChangeUserRoleMutationOptions = <
  TData = Awaited<ReturnType<typeof changeUserRole>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: ChangeUserRoleInputSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["changeUserRole"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeUserRole>>,
    { tenantUuid: string; userUuid: string; data: ChangeUserRoleInputSchema }
  > = (props) => {
    const { tenantUuid, userUuid, data } = props ?? {};

    return changeUserRole(tenantUuid, userUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: ChangeUserRoleInputSchema },
    TContext
  >;
};

export type ChangeUserRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeUserRole>>
>;
export type ChangeUserRoleMutationBody = ChangeUserRoleInputSchema;
export type ChangeUserRoleMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Change User Role
 */
export const useChangeUserRole = <
  TData = Awaited<ReturnType<typeof changeUserRole>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; userUuid: string; data: ChangeUserRoleInputSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; userUuid: string; data: ChangeUserRoleInputSchema },
  TContext
> => {
  const mutationOptions = getChangeUserRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get User Revenue
 */
export const getUserRevenue = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TechnicianRevenueResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}/revenue`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetUserRevenueQueryKey = (
  tenantUuid: string,
  userUuid: string
) => {
  return [`/api/v1/tenants/${tenantUuid}/users/${userUuid}/revenue`] as const;
};

export const getGetUserRevenueQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserRevenue>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserRevenue>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserRevenueQueryKey(tenantUuid, userUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRevenue>>> = ({
    signal,
  }) => getUserRevenue(tenantUuid, userUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && userUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserRevenue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserRevenueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserRevenue>>
>;
export type GetUserRevenueQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get User Revenue
 */

export function useGetUserRevenue<
  TData = Awaited<ReturnType<typeof getUserRevenue>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserRevenue>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserRevenueQueryOptions(
    tenantUuid,
    userUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get User Stats
 */
export const getUserStats = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}/stats`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetUserStatsQueryKey = (
  tenantUuid: string,
  userUuid: string
) => {
  return [`/api/v1/tenants/${tenantUuid}/users/${userUuid}/stats`] as const;
};

export const getGetUserStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserStats>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserStats>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserStatsQueryKey(tenantUuid, userUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserStats>>> = ({
    signal,
  }) => getUserStats(tenantUuid, userUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && userUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserStats>>
>;
export type GetUserStatsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get User Stats
 */

export function useGetUserStats<
  TData = Awaited<ReturnType<typeof getUserStats>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserStats>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserStatsQueryOptions(
    tenantUuid,
    userUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Connect Erp
 */
export const connectErp = (
  tenantUuid: string,
  erpUserLinkData: ErpUserLinkData,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/actions/connect-erp`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: erpUserLinkData,
      signal,
    },
    options
  );
};

export const getConnectErpMutationOptions = <
  TData = Awaited<ReturnType<typeof connectErp>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: ErpUserLinkData },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["connectErp"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof connectErp>>,
    { tenantUuid: string; data: ErpUserLinkData }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return connectErp(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: ErpUserLinkData },
    TContext
  >;
};

export type ConnectErpMutationResult = NonNullable<
  Awaited<ReturnType<typeof connectErp>>
>;
export type ConnectErpMutationBody = ErpUserLinkData;
export type ConnectErpMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Connect Erp
 */
export const useConnectErp = <
  TData = Awaited<ReturnType<typeof connectErp>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: ErpUserLinkData },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: ErpUserLinkData },
  TContext
> => {
  const mutationOptions = getConnectErpMutationOptions(options);

  return useMutation(mutationOptions);
};
