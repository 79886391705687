/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  BoostSendMagicLinkEmailInput,
  BoostSendMagicLinkSMSInput,
  BoostSendOTPSMSInput,
  BoostVerifyOTPSMSInput,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Send Magic Link Sms
 */
export const sendMagicLinkSms = (
  boostSendMagicLinkSMSInput: BoostSendMagicLinkSMSInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/send-magic-link`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostSendMagicLinkSMSInput,
      signal,
    },
    options
  );
};

export const getSendMagicLinkSmsMutationOptions = <
  TData = Awaited<ReturnType<typeof sendMagicLinkSms>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendMagicLinkSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendMagicLinkSms"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMagicLinkSms>>,
    { data: BoostSendMagicLinkSMSInput }
  > = (props) => {
    const { data } = props ?? {};

    return sendMagicLinkSms(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BoostSendMagicLinkSMSInput },
    TContext
  >;
};

export type SendMagicLinkSmsMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendMagicLinkSms>>
>;
export type SendMagicLinkSmsMutationBody = BoostSendMagicLinkSMSInput;
export type SendMagicLinkSmsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Magic Link Sms
 */
export const useSendMagicLinkSms = <
  TData = Awaited<ReturnType<typeof sendMagicLinkSms>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendMagicLinkSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BoostSendMagicLinkSMSInput },
  TContext
> => {
  const mutationOptions = getSendMagicLinkSmsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Otp Sms
 */
export const sendOtpSms = (
  boostSendOTPSMSInput: BoostSendOTPSMSInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/send-otp-sms`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostSendOTPSMSInput,
      signal,
    },
    options
  );
};

export const getSendOtpSmsMutationOptions = <
  TData = Awaited<ReturnType<typeof sendOtpSms>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendOtpSms"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendOtpSms>>,
    { data: BoostSendOTPSMSInput }
  > = (props) => {
    const { data } = props ?? {};

    return sendOtpSms(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BoostSendOTPSMSInput },
    TContext
  >;
};

export type SendOtpSmsMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendOtpSms>>
>;
export type SendOtpSmsMutationBody = BoostSendOTPSMSInput;
export type SendOtpSmsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Otp Sms
 */
export const useSendOtpSms = <
  TData = Awaited<ReturnType<typeof sendOtpSms>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BoostSendOTPSMSInput },
  TContext
> => {
  const mutationOptions = getSendOtpSmsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Otp
 */
export const sendOtp = (
  boostSendOTPSMSInput: BoostSendOTPSMSInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/send-otp`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostSendOTPSMSInput,
      signal,
    },
    options
  );
};

export const getSendOtpMutationOptions = <
  TData = Awaited<ReturnType<typeof sendOtp>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendOtp"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendOtp>>,
    { data: BoostSendOTPSMSInput }
  > = (props) => {
    const { data } = props ?? {};

    return sendOtp(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BoostSendOTPSMSInput },
    TContext
  >;
};

export type SendOtpMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendOtp>>
>;
export type SendOtpMutationBody = BoostSendOTPSMSInput;
export type SendOtpMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Otp
 */
export const useSendOtp = <
  TData = Awaited<ReturnType<typeof sendOtp>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BoostSendOTPSMSInput },
  TContext
> => {
  const mutationOptions = getSendOtpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Verify Otp Sms
 */
export const verifyOtpSms = (
  boostVerifyOTPSMSInput: BoostVerifyOTPSMSInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/verify-otp-sms`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostVerifyOTPSMSInput,
      signal,
    },
    options
  );
};

export const getVerifyOtpSmsMutationOptions = <
  TData = Awaited<ReturnType<typeof verifyOtpSms>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostVerifyOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["verifyOtpSms"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifyOtpSms>>,
    { data: BoostVerifyOTPSMSInput }
  > = (props) => {
    const { data } = props ?? {};

    return verifyOtpSms(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BoostVerifyOTPSMSInput },
    TContext
  >;
};

export type VerifyOtpSmsMutationResult = NonNullable<
  Awaited<ReturnType<typeof verifyOtpSms>>
>;
export type VerifyOtpSmsMutationBody = BoostVerifyOTPSMSInput;
export type VerifyOtpSmsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Verify Otp Sms
 */
export const useVerifyOtpSms = <
  TData = Awaited<ReturnType<typeof verifyOtpSms>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostVerifyOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BoostVerifyOTPSMSInput },
  TContext
> => {
  const mutationOptions = getVerifyOtpSmsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Verify Otp
 */
export const verifyOtp = (
  boostVerifyOTPSMSInput: BoostVerifyOTPSMSInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/verify-otp`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostVerifyOTPSMSInput,
      signal,
    },
    options
  );
};

export const getVerifyOtpMutationOptions = <
  TData = Awaited<ReturnType<typeof verifyOtp>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostVerifyOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["verifyOtp"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verifyOtp>>,
    { data: BoostVerifyOTPSMSInput }
  > = (props) => {
    const { data } = props ?? {};

    return verifyOtp(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BoostVerifyOTPSMSInput },
    TContext
  >;
};

export type VerifyOtpMutationResult = NonNullable<
  Awaited<ReturnType<typeof verifyOtp>>
>;
export type VerifyOtpMutationBody = BoostVerifyOTPSMSInput;
export type VerifyOtpMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Verify Otp
 */
export const useVerifyOtp = <
  TData = Awaited<ReturnType<typeof verifyOtp>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostVerifyOTPSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BoostVerifyOTPSMSInput },
  TContext
> => {
  const mutationOptions = getVerifyOtpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Magic Link Email
 */
export const sendMagicLinkEmail = (
  boostSendMagicLinkEmailInput: BoostSendMagicLinkEmailInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/send-magic-link-email`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostSendMagicLinkEmailInput,
      signal,
    },
    options
  );
};

export const getSendMagicLinkEmailMutationOptions = <
  TData = Awaited<ReturnType<typeof sendMagicLinkEmail>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendMagicLinkEmailInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["sendMagicLinkEmail"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    { data: BoostSendMagicLinkEmailInput }
  > = (props) => {
    const { data } = props ?? {};

    return sendMagicLinkEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BoostSendMagicLinkEmailInput },
    TContext
  >;
};

export type SendMagicLinkEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendMagicLinkEmail>>
>;
export type SendMagicLinkEmailMutationBody = BoostSendMagicLinkEmailInput;
export type SendMagicLinkEmailMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Magic Link Email
 */
export const useSendMagicLinkEmail = <
  TData = Awaited<ReturnType<typeof sendMagicLinkEmail>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { data: BoostSendMagicLinkEmailInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { data: BoostSendMagicLinkEmailInput },
  TContext
> => {
  const mutationOptions = getSendMagicLinkEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
