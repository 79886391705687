/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type JobBookingSource =
  (typeof JobBookingSource)[keyof typeof JobBookingSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobBookingSource = {
  inbound_call: "inbound_call",
  outbound_call: "outbound_call",
  manual: "manual",
} as const;
