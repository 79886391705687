/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Dev API
 * OpenAPI spec version: 1.0.0
 */

export type CommMethodType =
  (typeof CommMethodType)[keyof typeof CommMethodType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommMethodType = {
  email: "email",
  sms: "sms",
  phone: "phone",
  ringless_voicemail: "ringless_voicemail",
  mailer: "mailer",
} as const;
